import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component,  Input,  OnInit } from '@angular/core';
import {  FormBuilder, Validators} from '@angular/forms';
import { Logger } from '@core/logger.service';

import { ModalController, NavParams, ToastController } from '@ionic/angular';

import { formatApiErrors } from '@helpers/format-api-errors';
import { NewsTypeService } from '@http/news/news-type.service';
import { NewsType } from '@models/news-type.model';

const logger = new Logger('CreateNewsTypeComponent');

@Component({
	selector: 'app-web-create-news-type',
	templateUrl: './create-news-type.component.html',
	styleUrls: ['./create-news-type.component.scss'],
	
})
export class CreateNewsTypeComponent implements OnInit{


	constructor(
		public newsTypeService:NewsTypeService,
		public formBuilder: FormBuilder,
		public modalController:ModalController,
		public toastController: ToastController,
		public navParams:NavParams,
		){

	}


	public isLoading = false;

	public creating = true; 
	public submitted = false;

	public newsTypeId = null;
	public newsType: NewsType;
	public checkoutForm = this.formBuilder.group({
		type_name:[null,Validators.required],
		//clusters:new FormArray([])
	});
	get f() { return this.checkoutForm.controls; }
	@Input() id:any;
	ngOnInit(){
		let id = this.navParams.get('id');
		if(id){
			this.newsTypeId = id;
			this.creating = false;
			this.newsTypeService.getById(id).subscribe((data) => {
				this.newsType = (data as NewsType);
				this.checkoutForm.patchValue({
					'type_name':data.nom
				})
			});
		}
		
	}

	async displayErrorMessage(message) {
		const toast = await this.toastController.create({
			message: message,
			duration: 5000,
			color: "danger",
			mode: 'ios',
			position: "top"
		});
		toast.present();
	}

	public createNewsType(){
		


		this.submitted = true;
		if (this.checkoutForm.invalid) {
			return;
		}

		let nom = this.checkoutForm.get('type_name').value;

		if(!this.creating && nom == this.newsType.nom){
			return false;
		}
		this.isLoading = true;

		if(this.creating){
			this.newsTypeService.create({nom}).subscribe((newsType)=>{
				this.isLoading = false;
				this.modalController.dismiss(newsType);
			},(err)=>{
				logger.debug(err);
				this.isLoading = false;
				const errorsString = formatApiErrors(err.errors);
				this.displayErrorMessage(err.messages['error']+"</br>"+errorsString)
			});
		}else{
			this.newsTypeService.update({"nom":nom,'id':this.newsTypeId}).subscribe((newsType)=>{
				this.isLoading = false;
				this.modalController.dismiss(newsType);
			},(err)=>{
				logger.debug(err);
				this.isLoading = false;
				const errorsString = formatApiErrors(err.errors);
				this.displayErrorMessage(err.messages['error']+"</br>"+errorsString)
			});
		}
		
		
	}

	onReset() {
		this.submitted = false;
		this.checkoutForm.reset();
	}
}