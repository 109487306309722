import { Logger } from '@core/logger.service';
import TimeAgo from 'javascript-time-ago';
import fr from 'javascript-time-ago/locale/fr';
TimeAgo.addDefaultLocale(fr);
const timeAgoFunc = new TimeAgo('fr-Fr');
const log = new Logger('Notification');


declare global{
	interface Date{
		fromISO(s):any;
	}
}

Date.prototype.fromISO= (function(){
    var testIso = '2011-11-24T09:00:27+0200';
    // Chrome
    var diso= Date.parse(testIso);
    if(diso===1322118027000) return function(s){
        return new Date(Date.parse(s));
    }
    // JS 1.8 gecko
    var noOffset = function(s) {
      var day= s.slice(0,-5).split(/\D/).map(function(itm){
        return parseInt(itm, 10) || 0;
      });
      day[1]-= 1;
      day= new Date(Date.UTC.apply(Date, day));  
      var offsetString = s.slice(-5)
      var offset = parseInt(offsetString,10)/100; 
      if (offsetString.slice(0,1)=="+") offset*=-1;
      day.setHours(day.getHours()+offset);
      return day.getTime();
    }
    if (noOffset(testIso)===1322118027000) {
       return noOffset;
    }  
    return function(s){ // kennebec@SO + QTax@SO
        var day, tz, 
//        rx = /^(\d{4}\-\d\d\-\d\d([tT][\d:\.]*)?)([zZ]|([+\-])(\d{4}))?$/,
        rx = /^(\d{4}\-\d\d\-\d\d([tT][\d:\.]*)?)([zZ]|([+\-])(\d\d):?(\d\d))?$/,
            
        p= rx.exec(s) || [];
        if(p[1]){
            day= p[1].split(/\D/).map(function(itm){
                return parseInt(itm, 10) || 0;
            });
            day[1]-= 1;
            day= new Date(Date.UTC.apply(Date, day));
            if(!day.getDate()) return NaN;
            if(p[5]){
                tz= parseInt(p[5], 10)/100*60;
                if(p[6]) tz += parseInt(p[6], 10);
                if(p[4]== "+") tz*= -1;
                if(tz) day.setUTCMinutes(day.getUTCMinutes()+ tz);
            }
            return day;
        }
        return NaN;
    }
})()


export class Notification {
	id: string;
	id_user: string;
	type: string;
	data: string;
	created_at: Date;
	read_at: string;


	@content()
	content: any;

	@timeAgo()
	timeAgo:string;
}



 
 

export function content() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) { return JSON.parse(this.data) },
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}



export function timeAgo() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) { 
				//let created_at = Date.parse(this.created_at.date);
				

				let tmpDate = this.created_at.date.split('.')[0];
				var arr = tmpDate.split(/[- :]/),
				date = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);

				//let created_at = this.created_at.date+'-0200';
				//log.debug(created_at);
				//var date = new Date(Date.prototype.fromISO(created_at));
				
				let timeago =  timeAgoFunc.format(date);





				
				return timeago;
			},
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}