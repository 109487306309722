import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { of, from } from 'rxjs';
import { Meeting } from '@models/bbb/meeting.model';
import { Recording } from '@models/bbb/recording.model';
const logger = new Logger('BBBService');


@Injectable()
export class BBBService {

	private readonly _meetings = new BehaviorSubject<Meeting[]>([]);
	readonly meetings$ = this._meetings.asObservable();

	private readonly _recordings = new BehaviorSubject<Recording[]>([]);
	readonly recordings$ = this._recordings.asObservable();




	constructor(
		private apiService: ApiService
	) { }


	get meetings(): Meeting[] {
		return this._meetings.getValue();
	}


	set meetings(val: Meeting[]) {
		this._meetings.next(val);
	}

	get recordings(): Recording[] {
		return this._recordings.getValue();
	}


	set recordings(val: Recording[]) {
		this._recordings.next(val);
	}



	public getAll(): Observable<Meeting[]> {
		logger.debug('getAll');

		if (this._meetings.getValue().length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/meetings').pipe(
					map((data) => data.meetings.map((data) => {
						const meeting = new Meeting();
						Object.assign(meeting, data);
						return meeting;
					}))).subscribe((data) => {
						this.meetings = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<Meeting[]>);

		} else {

			return this.meetings$;
		}

	}

	public invite(data): Observable<string> {
		logger.debug('create', data);

		return this.apiService.post("/meetings/invite", data).pipe(map((data) => {
			return data.data.url;
		}));
	}

	
	public stopMeeting(data): Observable<boolean> {
		logger.debug('stopMeeting', data);

		return this.apiService.post("/meetings/stop", data).pipe(map((data) => {
			logger.debug(this.meetings);
			this.meetings = this.meetings.filter(item => item.meetingID != data.id);
			logger.debug(this.meetings);
			return true;
		}));
	}

	public deleteRecording(recordingId): Observable<boolean> {
		logger.debug('deleteRecording', recordingId);

		return this.apiService.delete("/meetings/records/"+recordingId).pipe(map((data) => {
			this.recordings = this.recordings.filter(item => item.recordID != recordingId);
			return true;
		}));
	}

	public joinMeeting(data): Observable<string> {
		logger.debug('joinMeeting', data);

		return this.apiService.post("/meetings/join", data).pipe(map((data) => {
			return data.data.url;
		}));
	}

	public getAllRecordings(): Observable<Recording[]> {
		logger.debug('getAllRecordings');

		if (this._recordings.getValue().length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/records').pipe(
					map((data) => data.recordings.map((data) => {
						const recording = new Recording();
						Object.assign(recording, data);
						return recording;
					}))).subscribe((data) => {
						this.recordings = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<Recording[]>);

		} else {

			return this.recordings$;
		}

	}

	public create(data): Observable<string> {
		logger.debug('create', data);

		return this.apiService.post("/meetings/create", data).pipe(map((data) => {
			return data.data.code;
		}));
	}

	




}