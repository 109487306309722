import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';

import { Salon } from '@models/salon.model';

const log = new Logger('SalonsService');


@Injectable()
export class StatsService {

	constructor(
		private apiService: ApiService
	) { }


    public statsModules(id_user, data): Observable<any> {
		log.debug('statsModules', data);

		let promise = new Promise((resolve, reject) => {
			this.apiService.post(`/stats/modules/${id_user}`, data).pipe(
				map((data) => data.data.map((data) => {
					let score = [];
					Object.assign(score, data);
					if(score['date_certif'] !== null){
						score['date_certif_format'] = new Date(score['date_certif']*1000).toLocaleDateString('fr-FR');
					}else{
						score['date_certif_format'] = '';
					}
					return score;
				}))).subscribe((data) => {
					resolve(data);
				});
		});

		return (from(promise) as Observable<any>);
	}

	public statsModulesExport(id_user, data): Observable<any> {
		log.debug('statsModulesExport', data);

		return this.apiService.post(`/stats/modules/export/${id_user}`, data);
	}

	public statsFlashforms(id_user, id_flashform): Observable<any> {
		log.debug('statsFlashforms', id_flashform);

		if(id_flashform == null){
			id_flashform = 0;
		}

		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/stats/flashforms/${id_user}/${id_flashform}`).pipe(
				map((data) => data.data.map((data) => {
					let score = [];
					Object.assign(score, data);
					return score;
				}))).subscribe((data) => {
					resolve(data);
				});
		});

		return (from(promise) as Observable<any>);
	}

	public statsFlashformsExport(id_user, id_flashform): Observable<any> {
		log.debug('statsFlashformsExport', id_flashform);

		if(id_flashform == null){
			id_flashform = 0;
		}

		return this.apiService.get(`/stats/flashforms/export/${id_user}/${id_flashform}`);
	}

	public statsClassrooms(id_user, id_classroom): Observable<any> {
		log.debug('statsClassrooms', id_classroom);
		
		if(id_classroom == null){
			id_classroom = 0;
		}

		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/stats/classrooms/${id_user}/${id_classroom}`).pipe(
				map((data) => data.data.map((data) => {
					let score = [];
					Object.assign(score, data);
					return score;
				}))).subscribe((data) => {
					resolve(data);
				});
		}); 

		return (from(promise) as Observable<any>);
	}

	public statsClassroomsExport(id_user, id_classroom): Observable<any> {
		log.debug('statsClassroomsExport', id_classroom);

		if(id_classroom == null){
			id_classroom = 0;
		}

		return this.apiService.get(`/stats/classrooms/export/${id_user}/${id_classroom}`);
	}

	public statsSalons(): Observable<Salon[]> {

		let promise = new Promise((resolve, reject) => {
			this.apiService.get('/stats/salons').pipe(
				map((data) => data.salons.map((data) => {
					let salon = new Salon();
					Object.assign(salon, data);
					return salon;
				}))).subscribe((data) => {
					resolve(data);
				});
		});

		return (from(promise) as Observable<Salon[]>);
	}

	public statsSalonsExport(id_salon, id_enseigne = null): Observable<any> {
		log.debug('statsSalonsExport', id_salon);

		if(id_enseigne === null){
			return this.apiService.get(`/stats/salons/export/${id_salon}`);
		}else{
			return this.apiService.get(`/stats/salons/export/${id_salon}/${id_enseigne}`);
		}
	}

	public statsDocuments(): Observable<any> {
		log.debug('statsDocuments');

		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/stats/documents`).pipe(
				map((data) => data.data.map((data) => {
					let score = [];
					Object.assign(score, data);
					return score;
				}))).subscribe((data) => {
					resolve(data);
				});
		});

		return (from(promise) as Observable<any>);
	}
}