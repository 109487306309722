import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component,  Input,  OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Logger } from '@core/logger.service';

import { ModalController, NavParams, ToastController } from '@ionic/angular';

import { formatApiErrors } from '@helpers/format-api-errors';

import { Category } from '@models/category.model';
import { Theme } from '@models/theme.model';

import { ThemesService } from '@http/themes/themes.service';
import { CategoryService } from '@http/category/category.service';

const logger = new Logger('CreateThemeComponent');

@Component({
	selector: 'app-web-create-theme',
	templateUrl: './create-theme.component.html',
	styleUrls: ['./create-theme.component.scss'],
	
})
export class CreateThemeComponent implements OnInit{

	constructor(
		public themesService:ThemesService,
		public categoryService:CategoryService,
		public formBuilder: FormBuilder,
		public modalController:ModalController,
		public toastController: ToastController,
		public navParams:NavParams,
		){

	}

	public isLoading = false;

	public creating = true; 
	public submitted = false;

	public categories: Category[] = [];

	public themeId = null;
	public theme: Theme;
	public checkoutForm = this.formBuilder.group({
		name:[null,Validators.required],
		categories: new FormArray([]),
		filter: null
	});
	get f() { return this.checkoutForm.controls; }
	@Input() id:any;
	ngOnInit(){
		this.categoryService.categories$.subscribe((categories) => {
			this.categories = categories;
			const formArray = this.checkoutForm.get('categories') as FormArray;

			this.categories.forEach(Category => {
				let formGroup = new FormGroup({
					id: new FormControl(Category.id),
					name: new FormControl(Category.nom),
					checked: new FormControl(false)
				});

				formArray.push(formGroup);
			});
		});

		let id = this.navParams.get('id');
		if(id){
			this.themeId = id;
			this.creating = false;
			this.themesService.getById(id).subscribe((data) => {
				this.theme = (data as Theme);
				this.checkoutForm.patchValue({
					'name':data.nom,
					'categories':this.categoryService.categories.map((category)=>{return (this.theme.categories.find(cat=>cat.id === category.id))?{checked:true}:null;}),
					'filter': data.filter
				})
			});
		}
		
	}

	async displayErrorMessage(message) {
		const toast = await this.toastController.create({
			message: message,
			duration: 5000,
			color: "danger",
			mode: 'ios',
			position: "top"
		});
		toast.present();
	}

	public createTheme(){
		
		this.submitted = true;
		if (this.checkoutForm.invalid) {
			return;
		}

		let nom = this.checkoutForm.get('name').value;

		this.isLoading = true;

		let data = {
			nom: nom,
			categories: this.checkoutForm.get('categories').value.filter(i => i.checked === true).map((category) => { return category.id }),
			filter: this.checkoutForm.get('filter').value
		}

		if(this.creating){
			this.themesService.create(data).subscribe((theme)=>{
				this.isLoading = false;
				this.modalController.dismiss(theme);
			},(err)=>{
				logger.debug(err);
				this.isLoading = false;
				const errorsString = formatApiErrors(err.errors);
				this.displayErrorMessage(err.messages['error']+"</br>"+errorsString)
			});
		}else{
			data['id'] = this.themeId;
			this.themesService.update(data).subscribe((theme)=>{
				this.isLoading = false;
				this.modalController.dismiss(theme);
			},(err)=>{
				logger.debug(err);
				this.isLoading = false;
				const errorsString = formatApiErrors(err.errors);
				this.displayErrorMessage(err.messages['error']+"</br>"+errorsString)
			});
		}
		
		
	}

	onReset() {
		this.submitted = false;
		this.checkoutForm.reset();
	}
}