import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Rdv } from '@models/rdv.model';

const log = new Logger('RdvService');


@Injectable()
export class RdvService {




	constructor(
		private apiService: ApiService
	) { }

	private readonly _rdvs = new BehaviorSubject<Rdv[]>([]);
	readonly rdvs$ = this._rdvs.asObservable();

	get rdvs(): Rdv[] {
		return this._rdvs.getValue();
	}

	set rdvs(val: Rdv[]) {
		this._rdvs.next(val);
	}

	public create(data): Observable<Rdv> {
		log.debug('create', data);

		return this.apiService.post("/rdv/", data).pipe(map((data) => {
			let rdv = new Rdv();
			Object.assign(rdv, data.data);
			this.rdvs = [...this.rdvs, rdv];
			return rdv;
		}));
	}

	
	public update(data): Observable<Rdv> {
		log.debug('update', data);

		return this.apiService.put(`/rdv/${data.id}`, data).pipe(map((data) => {
			let rdv = new Rdv();
			Object.assign(rdv, data.data);
			return rdv;
		}));
	}


	public getById(id): Observable<Rdv> {
		log.debug('getById',id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/rdv/${id}`).pipe(
				map((data) => {
					let rdv = new Rdv();
					Object.assign(rdv, data);
					return rdv;
				})).subscribe((data)=>{resolve(data)});
		})
		return (from(promise) as Observable<Rdv>);
	}

	public getAll(): Observable<Rdv[]> {
		log.debug('getAll');

		if (this.rdvs.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/rdv').pipe(
					map((data) => data.rdv.map((data) => {
						let rdv = new Rdv();
						Object.assign(rdv, data);
						return rdv;
					}))).subscribe((data) => {
						this.rdvs = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<Rdv[]>);

		} else {

			return this.rdvs$;
		}

	}
	
	public validate(id):Observable<boolean>
	{
		log.debug('validate', id);
		return this.apiService.get(`/rdv/validate/${id}`).pipe(map((data) => {
			let rdv = new Rdv();
			Object.assign(rdv, data.data);

			let rdvs = this.rdvs.reduce((ds, d) => {
				let newD = d;
				if (d.id === rdv.id) {
					newD = Object.assign({}, d, rdv);
				}
				return ds.concat(newD);
			}, []);

			this.rdvs = [...rdvs];
			
			return true;
		}));
	}

	public getFormContent():any
	{
		log.debug('getFormContent');
		let promise = new Promise((resolve, reject) => {
			this.apiService.get('/rdv/form').subscribe((data) => {
					resolve(data);
				});
		});
		return (from(promise) as any);
	}

	public delete(id):Observable<boolean>
	{
		log.debug('delete', id);
		return this.apiService.delete(`/rdv/${id}`).pipe(map((data) => {
			this.rdvs = this.rdvs.filter(item => item.id != id);
			return true;
		}));
	}
}