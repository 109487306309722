
import { AuthService } from '@core/authentication/authentication.service';




export function configurationFactory(
    authService:AuthService
) {

    return () => {
        return new Promise((resolve, reject) => {

            return authService.populate().then(() => {
				resolve(true);
			})
        });
    };
}
