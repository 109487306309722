import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule, Injector, InjectionToken, Inject } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { IonicStorageModule } from '@ionic/storage-angular';

import { configurationFactory } from './app.initializers';

//app
//import { AppLayoutComponent } from './shared/layouts/app-layout/app-layout.component';
//import { WebModule } from './platform/web/web.module.ts.bak';
//import { MobileModule } from './platform/mobile/mobile.module.ts.bak';
//import { BackEndModule } from './platform/backend/backend.module.ts.bak';
import { WebComponentModule } from 'src/app/shared/components/web/web.component.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

//libs
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataTablesModule } from 'angular-datatables';
import { AuthService } from '@core/authentication/authentication.service';

import { RollbarService, rollbarFactory, RollbarErrorHandler } from './rollbar';

import '@teamhive/stencil-scorm-viewer/dist/scormviewer';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { Logger } from '@core/logger.service';
import { Device } from '@ionic-native/device/ngx';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { SharedComponentModule } from '@components/shared/shared.component.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const logger = new Logger('Global');

export class CustomHammerConfig extends HammerGestureConfig {
	overrides = {
		press: { time: 1000 }, // default: 251 ms
		pinch: { enable: false },
		rotate: { enable: false },
	};
}

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		IonicModule.forRoot(),
		IonicStorageModule.forRoot(),
		CoreModule,
		AppRoutingModule,
		BrowserModule,
		PinchZoomModule,
		HammerModule,
		BrowserAnimationsModule,
		AutocompleteLibModule,
		SharedModule,
		PdfViewerModule,
		WebComponentModule,
		SharedComponentModule,
		DataTablesModule.forRoot(),
		NgbModule,
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: configurationFactory,
			deps: [AuthService],
			multi: true,
		},
		Device,
		FileTransfer,
		FCM,

		{ provide: ErrorHandler, useClass: RollbarErrorHandler },
		{ provide: RollbarService, useFactory: rollbarFactory },
		StatusBar,
		SplashScreen,
		{ provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
