import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Logger } from '@core/logger.service';
import { ClusterService } from '@http/cluster/cluster.service';
import { EnseigneService } from '@http/enseigne/enseigne.service';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { Cluster } from '@models/cluster.model';
import { formatApiErrors } from '@helpers/format-api-errors';
import { Enseigne } from '@models/enseigne.model';

const logger = new Logger('CreateEnseigneComponent');

@Component({
	selector: 'app-web-create-enseigne',
	templateUrl: './create-enseigne.component.html',
	styleUrls: ['./create-enseigne.component.scss'],

})
export class CreateEnseigneComponent implements OnInit {


	constructor(
		public clusterService: ClusterService,
		public formBuilder: FormBuilder,
		public enseigneService: EnseigneService,
		public modalController: ModalController,
		public toastController: ToastController,

		public navParams: NavParams
	) {

	}
	public isLoading = false;
	public clusters: Cluster[] = [];
	public checkoutForm = this.formBuilder.group({
		enseigne_name: "",
		//clusters:new FormArray([])
	});

	public creating = true;

	public enseigneId = null;
	public enseigne:Enseigne;
	@Input() id: any;
	ngOnInit() {
		let id = this.navParams.get('id');
		if (id) {
			this.enseigneId = id;
			this.creating = false;
			this.enseigneService.getById(id).subscribe((data) => {
				this.enseigne = (data as Enseigne);
				this.checkoutForm.patchValue({
					'enseigne_name': data.nom,
				})
			});
		}

	}

	async displayErrorMessage(message) {
		const toast = await this.toastController.create({
			message: message,
			duration: 5000,
			color: "danger",
			mode: 'ios',
			position: "top"
		});
		toast.present();
	}

	public createEnseigne() {
		//let reseaux = this.checkoutForm.get('clusters').value.filter(i => i.checked === true);
		let nom = this.checkoutForm.get('enseigne_name').value;

		let data = {
			'nom' : nom,
			'color' : '#000000'
		};
		
		if(this.enseigne !== undefined){
			if (this.enseigne.nom != nom) {
				data['nom'] = nom;
			}else{
				return false;
			}
		}

		this.isLoading = true;
		if (this.creating) {

			
			this.enseigneService.create(data).subscribe((enseigne) => {
				this.isLoading = false;
				this.modalController.dismiss(enseigne);
				
			}, (err) => {
				logger.debug(err);
				this.isLoading = false;
				const errorsString = formatApiErrors(err.errors);
				this.displayErrorMessage(err.messages['error'] + "</br>" + errorsString)
			});
		} else {

			data['id'] = this.enseigneId;
			this.enseigneService.update(data).subscribe((newsType) => {
				this.isLoading = false;
				this.modalController.dismiss(newsType);
			}, (err) => {
				logger.debug(err);
				this.isLoading = false;
				const errorsString = formatApiErrors(err.errors);
				this.displayErrorMessage(err.messages['error'] + "</br>" + errorsString)
			});
		}


	}
}