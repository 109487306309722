import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';

import { ApiService, JwtService, NewsService, NewsTypeService, UserService, FileService, ThemesService } from './http';
import { AuthService } from './authentication/authentication.service';
import { IsSignedInGuard } from './guards/web/is-signed-in-guard.guard';
import { MagasinService } from '@http/magasin/magasin.service';
import { EnseigneService } from '@http/enseigne/enseigne.service';
import { ClusterService } from '@http/cluster/cluster.service';
import { RankService } from '@http/rank/rank.service';
import { CategoryService } from '@http/category/category.service';
import { ModuleService } from '@http/module/module.service';
import { FlashformsService } from '@http/flashforms/flashforms.service';
import { FlashformsQuestionService } from '@http/flashforms/flashforms-question.service';
import { QuestionService } from '@http/question/question.service';
import { TypesQuestionService } from '@http/typesquestion/types-question.service';
import { CertificationService } from '@http/certification/certification.service';
import { SalonsService } from '@http/salons/salons.service';
import { SalonQuestionService } from '@http/salons/salons-question.service';
import { DocumentsTypeService } from '@http/documents/documents-type.service';
import { DocumentsService } from '@http/documents/documents.service';
import { RdvService } from '@http/rdv/rdv.service';
import { RdvbandatesService } from '@http/rdv/rdvbandates.service';
import { LoggerService } from '@http/logger/logger.service';
import { LoggerTypeService } from '@http/logger/logger-type.service';
import { StatsService } from '@http/stats/stats.service';
import { NotificationService } from '@http/notifications/notification.service';
import { IsAdminGuard } from './guards/web/is-admin.guard';
import { IsAdministrateurGuard } from './guards/web/is-administrateur.guard';
import { RdvGuard } from './guards/web/rdv.guard';
import { File } from '@ionic-native/file/ngx';
import { Zip } from '@ionic-native/zip/ngx';
import { DevicesService } from '@http/devices/devices.service';
import { ForumQuestionService } from '@http/forum/questions/forum-questions.service';
import { ClassroomsService } from '@http/classrooms/classrooms.service';
import { ClassroomQuestionService } from '@http/classrooms/classrooms-question.service';
import { BBBService } from '@http/bbb/bbb.service';
import { ChallengesService } from '@http/challenges/challenges.service';
import { MeetingsService } from '@http/meetings/meetings.service';
import { IsFormateurGuard } from './guards/web/is_formateur.guard';
@NgModule({
	imports: [CommonModule, HttpClientModule],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },

		ApiService,
		AuthService,
		MagasinService,
		RankService,
		CategoryService,
		ModuleService,
		QuestionService,
		TypesQuestionService,
		CertificationService,
		EnseigneService,
		NewsTypeService,
		LoggerTypeService,
		LoggerService,
		ThemesService,
		DocumentsTypeService,
		DocumentsService,
		NewsService,
		ClusterService,
		FileService,
		IsSignedInGuard,
		IsAdminGuard,
		NotificationService,
		IsAdministrateurGuard,
		IsFormateurGuard,
		RdvGuard,
		JwtService,
		UserService,
		SalonsService,
		SalonQuestionService,
		RdvService,
		RdvbandatesService,
		DevicesService,
		ForumQuestionService,
		File,
		Zip,
		ChallengesService,
		BBBService,
		ClassroomsService,
		ClassroomQuestionService,
		StatsService,
		FlashformsService,
		FlashformsQuestionService,
		MeetingsService,
	],
	exports: [HttpClientModule],
	declarations: [],
})
export class CoreModule {}
