import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/core/authentication/authentication.service';
import { Router } from "@angular/router";

import { User } from '@models/user.model';
import { Ranks } from "@helpers/ranks-enum";

@Component({
	selector: 'app-web-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
	
	constructor(
		private _router: Router,
		private auth: AuthService
	) {}
	public currentUser:User = null;

	public is_admin:Boolean = false;
	public is_formateur:Boolean = false;
	public is_tuteur:Boolean = false;
	public is_manager_tuteur:Boolean = false;
	public show_rdv:Boolean = false;

  	ngOnInit() {
    	this.currentUser = this.auth.user;

		switch(parseInt(this.currentUser.rank)){
			case Ranks.RANK_TUTEUR:
				this.is_tuteur = true;
				break;
			case Ranks.RANK_MANAGER_TUTEUR:
				this.is_manager_tuteur = true;
				break;
			case Ranks.RANK_FORMATEUR:
				this.is_formateur = true;
				break;
			case Ranks.RANK_SUPERVISEUR:
			case Ranks.RANK_ADMIN:
			case Ranks.RANK_SUPER:
				this.is_admin = true;
				this.show_rdv = true;
				break;
			default:
				this._router.navigate(['/home'], {  replaceUrl: true });
				break;
		}

		if(parseInt(this.currentUser.id) == 2011){
			this.show_rdv = true;
		}
  	}
}
