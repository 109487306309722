import { Pipe, PipeTransform } from '@angular/core';
import { File } from '@ionic-native/file/ngx';

@Pipe({ name: 'ModuleExist' })
export class ModuleExistPipe implements PipeTransform {
	constructor(private file: File) { }
	transform(value: string,trigger:any): any {
		
		return new Promise((resolve, reject) => {
			
			if(value == "" || value == null){
				resolve(false);
			}else{
				this.file.checkDir(this.file.dataDirectory, value).then((data) => {
					
					resolve(data);
				},(err)=>{
					
					resolve(false);
				}).catch(()=>{
					resolve(false);
				});
			}
			
		})
	}
}