import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { of, from } from 'rxjs';
import { Meeting } from '@models/bbb/meeting.model';
import { Recording } from '@models/bbb/recording.model';
const logger = new Logger('MeetingsService');

@Injectable()
export class MeetingsService {
	constructor(private apiService: ApiService) {}

	public create(): Observable<string> {
		logger.debug('create');

		return this.apiService.get('/meetings/create8x8').pipe(
			map((data) => {
				return data;
			})
		);
	}
}
