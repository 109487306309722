import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Classroom } from '@models/classroom.model';

const log = new Logger('ClassroomsService');


@Injectable()
export class ClassroomsService {




	constructor(
		private apiService: ApiService
	) { }

	private readonly _classrooms = new BehaviorSubject<Classroom[]>([]);
	readonly classrooms$ = this._classrooms.asObservable();

	private readonly _past_classrooms_user = new BehaviorSubject<Classroom[]>([]);
	readonly past_classrooms_user$ = this._past_classrooms_user.asObservable();

	private readonly _current_classrooms_user = new BehaviorSubject<Classroom[]>([]);
	readonly current_classrooms_user$ = this._current_classrooms_user.asObservable();

	private readonly _coming_classrooms_user = new BehaviorSubject<Classroom[]>([]);
	readonly coming_classrooms_user$ = this._coming_classrooms_user.asObservable();


	private readonly _user_has_classrooms = new BehaviorSubject<boolean>(null);
	readonly user_has_classrooms$ = this._user_has_classrooms.asObservable();

	get classrooms(): Classroom[] {
		return this._classrooms.getValue();
	}

	set classrooms(val: Classroom[]) {
		this._classrooms.next(val);
	}


	get user_has_classrooms(): boolean{
		return this._user_has_classrooms.getValue();
	}
	set user_has_classrooms(val:boolean){
		this._user_has_classrooms.next(val);
	}

	get past_classrooms_user(): Classroom[] {
		return this._past_classrooms_user.getValue();
	}

	set past_classrooms_user(val: Classroom[]) {
		this._past_classrooms_user.next(val);
	}


	get current_classrooms_user(): Classroom[] {
		return this._current_classrooms_user.getValue();
	}

	set current_classrooms_user(val: Classroom[]) {
		this._current_classrooms_user.next(val);
	}


	get coming_classrooms_user(): Classroom[] {
		return this._coming_classrooms_user.getValue();
	}

	set coming_classrooms_user(val: Classroom[]) {
		this._coming_classrooms_user.next(val);
	}


	public create(data): Observable<Classroom> {
		log.debug('create', data);

		return this.apiService.post("/classrooms/", data).pipe(map((data) => {
			let classroom = new Classroom();
			Object.assign(classroom, data.data);
			this.classrooms = [...this.classrooms, classroom];
			return classroom;
		}));
	}

	
	public update(data): Observable<Classroom> {
		log.debug('update', data);

		return this.apiService.put(`/classrooms/${data.id}`, data).pipe(map((data) => {
			let classroom = new Classroom();
			Object.assign(classroom, data.data);

			let classrooms = this.classrooms.reduce((ds, d) => {

				if (d.id === classroom.id) {
					Object.assign(d, classroom);
				}
				return ds.concat(d);
			}, []);

			this.classrooms = [...classrooms];

			return classroom;
		}));
	}


	public getById(id): Observable<Classroom> {
		log.debug('getById',id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/classrooms/${id}`).pipe(
				map((data) => {
					let classroom = new Classroom();
					Object.assign(classroom, data);
					return classroom;
				})).subscribe((data)=>{resolve(data)});
		})
		return (from(promise) as Observable<Classroom>);
	}



	public getAll(): Observable<Classroom[]> {
		log.debug('getAll');

		// if (this.classrooms.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/classrooms').pipe(
					map((data) => data.classrooms.map((data) => {
						let classrooms = new Classroom();
						Object.assign(classrooms, data);
						return classrooms;
					}))).subscribe((data) => {
						this.classrooms = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<Classroom[]>);

		/* } else {

			return this.classrooms$;
		} */

	}

	public createScore(data):Observable<boolean>
	{
		log.debug('createScore');
		return this.apiService.post(`/classrooms/score`,data).pipe(map((data) => {
			log.debug(data);
			//this.classrooms = this.classrooms.filter(item => item.id != id);
			return true;
		}));
	}

	public duplicate(id_classroom):Observable<boolean> {
		log.debug('duplicate classroom', id_classroom);

		return this.apiService.get(`/classrooms/${id_classroom}/duplicate`).pipe(map((data) => {
			let classroom = new Classroom();
			Object.assign(classroom, data.data);
			this.classrooms = [...this.classrooms, classroom];
			return true;
		}));
	}

	public delete(id):Observable<boolean>
	{
		log.debug('delete', id);
		return this.apiService.delete(`/classrooms/${id}`).pipe(map((data) => {
			this.classrooms = this.classrooms.filter(item => item.id != id);
			return true;
		}));
	}

	public getAllByUserId(userId:string){
		log.debug('getAllByUserId');

		this.current_classrooms_user = [];
		this.past_classrooms_user = [];
		this.coming_classrooms_user = [];
		
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/classrooms/user/${userId}`).subscribe((data) => {
					log.debug(data);
					this.user_has_classrooms = data['classrooms']['current'].length > 0;
					// test on all: data['classrooms']['past'].length != 0 || data['classrooms']['current'] != 0 || data['classrooms']['coming'] != 0 ;

					
					data['classrooms']['past'].map((data) => {
						let classroom = new Classroom();
						Object.assign(classroom, data);
						this.past_classrooms_user= [...this.past_classrooms_user,classroom];
					})

					data['classrooms']['current'].map((data) => {
						let classroom = new Classroom();
						Object.assign(classroom, data);
						this.current_classrooms_user= [...this.current_classrooms_user,classroom];
					})

					data['classrooms']['coming'].map((data) => {
						let classroom = new Classroom();
						Object.assign(classroom, data);
						this.coming_classrooms_user= [...this.coming_classrooms_user,classroom];
					})

				
					resolve(data);
				});
		});
		return (from(promise) as Observable<Classroom[]>);
	}
}