import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Typesquestion } from "@models/typesquestion.model";
import { LoggerType } from '@models/logger-type.model';

const log = new Logger('LoggerTypeService');


@Injectable()
export class LoggerTypeService {

	constructor(
		private apiService: ApiService
	) { }

	private readonly _types = new BehaviorSubject<LoggerType[]>([]);
	readonly types$ = this._types.asObservable();	

    get types(): LoggerType[] {
		return this._types.getValue();
	}

	set types(val: LoggerType[]) {
		this._types.next(val);
	}

	public getAll(): Observable<LoggerType[]> {
		log.debug('getAll');

		if (this.types.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get(`/loggertypes`).pipe(
					map((data) => data.types.map((data) => {
						let type = new LoggerType();
						Object.assign(type, data);
						return type;
					}))).subscribe((data) => {
						this.types = [ ...data];						
						resolve(data);
					});
			});

			return (from(promise) as Observable<LoggerType[]>);

		} else {
			return this.types$;
		}

	}
}