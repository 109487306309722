import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Salon } from "@models/salon.model";
import { SalonQuestion } from "@models/salon-question.model";

const log = new Logger('SalonQuestionService');


@Injectable()
export class SalonQuestionService {

	constructor(
		private apiService: ApiService
	) { }

	private readonly _questions = new BehaviorSubject<SalonQuestion[]>([]);
	readonly questions$ = this._questions.asObservable();

	get questions(): SalonQuestion[] {
		return this._questions.getValue();
	}

	set questions(val: SalonQuestion[]) {
		this._questions.next(val);
	}

	public create(data): Observable<SalonQuestion> {
		log.debug('create', data);

		return this.apiService.post("/salonsquestions/", data).pipe(map((data) => {
			let question = new SalonQuestion();
			Object.assign(question, data.data);
			this.questions = [...this.questions, question];
			return question;
		}));
	}


	public update(data): Observable<SalonQuestion> {
		log.debug('update', data);

		return this.apiService.put(`/salonsquestions/${data.id}`, data).pipe(map((data) => {
			let question = new SalonQuestion();
			Object.assign(question, data.data);

			let questions = this.questions.reduce((ds, d) => {

				if (d.id === question.id) {
					Object.assign(d, question);
				}
				return ds.concat(d);
			}, []);

			this.questions = [...questions];

			return question;
		}));
	}


	public getById(id): Observable<SalonQuestion> {
		log.debug('getById', id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/salonsquestion/${id}`).pipe(
				map((data) => {
					let question = new SalonQuestion();
					Object.assign(question, data);
					return question;
				})).subscribe((data) => { resolve(data) });
		})
		return (from(promise) as Observable<SalonQuestion>);
	}

	public getAll(id_salon): Observable<SalonQuestion[]> {
		log.debug('getAll');


		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/salonsquestions/${id_salon}`).pipe(
				map((data) => data.questions.map((data) => {
					let question = new SalonQuestion();
					Object.assign(question, data);
					return question;
				}))).subscribe((data) => {
					this.questions = [...data];
					resolve(data);
				});
		});

		return (from(promise) as Observable<SalonQuestion[]>);



	}


	public delete(id): Observable<boolean> {
		log.debug('delete', id);
		return this.apiService.delete(`/salonsquestions/${id}`).pipe(map((data) => {
			this.questions = this.questions.filter(item => item.id != id);
			return true;
		}));
	}
}