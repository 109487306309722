import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component,  Input,  OnInit } from '@angular/core';
import {  FormBuilder, Validators} from '@angular/forms';
import { Logger } from '@core/logger.service';

import { ModalController, NavParams, ToastController } from '@ionic/angular';

import { formatApiErrors } from '@helpers/format-api-errors';
import { Category } from '@models/category.model';
import { CategoryService } from '@http/category/category.service';

const logger = new Logger('CreateCategoryComponent');

@Component({
	selector: 'app-web-create-category',
	templateUrl: './create-category.component.html',
	styleUrls: ['./create-category.component.scss'],
	
})
export class CreateCategoryComponent implements OnInit{


	constructor(
		public categoryService:CategoryService,
		public formBuilder: FormBuilder,
		public modalController:ModalController,
		public toastController: ToastController,
		public navParams:NavParams,
		){

	}


	public isLoading = false;

	public creating = true; 
	public submitted = false;

	public categoryId = null;
	public category: Category;
	public checkoutForm = this.formBuilder.group({
		name:[null,Validators.required],
	});
	get f() { return this.checkoutForm.controls; }
	@Input() id:any;
	ngOnInit(){
		let id = this.navParams.get('id');
		if(id){
			this.categoryId = id;
			this.creating = false;
			this.categoryService.getById(id).subscribe((data) => {
				this.category = (data as Category);
				this.checkoutForm.patchValue({
					'name':data.nom
				})
			});
		}
		
	}

	async displayErrorMessage(message) {
		const toast = await this.toastController.create({
			message: message,
			duration: 5000,
			color: "danger",
			mode: 'ios',
			position: "top"
		});
		toast.present();
	}

	public createCategory(){
		
		this.submitted = true;
		if (this.checkoutForm.invalid) {
			return;
		}

		let nom = this.checkoutForm.get('name').value;

		if(!this.creating && nom == this.category.nom){
			return false;
		}
		this.isLoading = true;

		if(this.creating){
			this.categoryService.create({nom}).subscribe((category)=>{
				this.isLoading = false;
				this.modalController.dismiss(category);
			},(err)=>{
				logger.debug(err);
				this.isLoading = false;
				const errorsString = formatApiErrors(err.errors);
				this.displayErrorMessage(err.messages['error']+"</br>"+errorsString)
			});
		}else{
			this.categoryService.update({"nom":nom,'id':this.categoryId}).subscribe((category)=>{
				this.isLoading = false;
				this.modalController.dismiss(category);
			},(err)=>{
				logger.debug(err);
				this.isLoading = false;
				const errorsString = formatApiErrors(err.errors);
				this.displayErrorMessage(err.messages['error']+"</br>"+errorsString)
			});
		}
		
		
	}

	onReset() {
		this.submitted = false;
		this.checkoutForm.reset();
	}
}