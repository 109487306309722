import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Certification } from "@models/certification.model";

const log = new Logger('CertificationService');


@Injectable()
export class CertificationService {

	constructor(
		private apiService: ApiService
	) { }

	private readonly _certifications = new BehaviorSubject<Certification[]>([]);
	readonly certifications$ = this._certifications.asObservable();	

    get certifications(): Certification[] {
		return this._certifications.getValue();
	}

	set certifications(val: Certification[]) {
		this._certifications.next(val);
	}

	public getAll(): Observable<Certification[]> {
		log.debug('getAll');

		if (this.certifications.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get(`/certifications`).pipe(
					map((data) => data.certifications.map((data) => {
						let certif = new Certification();
						Object.assign(certif, data);
						return certif;
					}))).subscribe((data) => {
						this.certifications = [ ...data];						
						resolve(data);
					});
			});

			return (from(promise) as Observable<Certification[]>);

		} else {
			return this.certifications$;
		}
	}

	public getById(id): Observable<Certification> {
		log.debug('getById',id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/certifications/${id}`).pipe(
				map((data) => {
					let certif = new Certification();
					Object.assign(certif, data);
					return certif;
				})).subscribe((data)=>{resolve(data)});
		})
		return (from(promise) as Observable<Certification>);
	}
}