import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable,} from 'rxjs';

import { ApiService } from '../api.service';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Cluster } from '@models/cluster.model';

import { of,from } from 'rxjs';

const log = new Logger('ClusterService');


@Injectable()
export class ClusterService {


	 

	private readonly _clusters = new BehaviorSubject<Cluster[]>([]);
	readonly clusters$ = this._clusters.asObservable();



	get clusters(): Cluster[] {
		return this._clusters.getValue();
	}


	set clusters(val: Cluster[]) {
		this._clusters.next(val);
	}


	constructor(
		private apiService: ApiService
	) { }



	public getAll():Observable<Cluster[]>{
		log.debug('getAll');

		if(this.clusters.length == 0){
			let promise = new Promise( (resolve,reject)=>{  this.apiService.get('/reseaux').pipe(
				
						map((data)=> data.reseaux.map((data)=>{
							let cluster = new Cluster();
							Object.assign(cluster,data);
							return cluster;
					}))).subscribe((data)=>{
						this.clusters = [...this.clusters, ...data];
						resolve(data);
					});
				});
			return (from(promise) as Observable<Cluster[]>);
			 
		}else{

			return this.clusters$;
		}
		
	}


	


}