import { Question } from "./question.model";
import { User } from "./user.model";

export class ChallengeQuestion{
	id:string;
	id_challenge:string;
	id_question:string;
	private _question:Question;

	get question():Question{
		return this._question;
	}

	set question(question){
		this._question = Object.assign(new Question(),question)
	}


}