import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Logger } from '@core/logger.service';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { formatApiErrors } from '@helpers/format-api-errors';

import { DomSanitizer} from '@angular/platform-browser';
import { FileService } from '@http/files/file.service';

const logger = new Logger('CreateEnseigneComponent');

@Component({
	selector: 'app-web-view-pdf',
	templateUrl: './view-pdf.component.html',
	styleUrls: ['./view-pdf.component.scss'],

})
export class ViewPDFComponent implements OnInit {


	constructor(
		public formBuilder: FormBuilder,
		public modalController: ModalController,
		public toastController: ToastController,
        public fileService:FileService,
        public domSanitizer:DomSanitizer,
		public navParams: NavParams
	) {

	}

    public url_pdf = null;

	@Input() pdf: any;
    @Input() type: any;
	ngOnInit() {
        this.pdf = this.navParams.get('pdf');
        this.type = this.navParams.get('type');

        var current_url = this.fileService.getFileRaw(this.fileService.types[this.type],this.pdf);
        this.url_pdf = this.domSanitizer.bypassSecurityTrustResourceUrl(current_url);
	}
}