import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Logger } from '@core/logger.service';
import { RdvService } from '@http/rdv/rdv.service';
import { RdvbandatesService } from '@http/rdv/rdvbandates.service';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { AuthService } from '@core/authentication/authentication.service';
import { User } from '@models/user.model';
import { ChallengesService } from '@http/challenges/challenges.service';
import { ModuleService } from '@http/module/module.service';
import { Module } from '@models/module.model';
import { QuestionService } from '@http/question/question.service';

const logger = new Logger('CreateChallengeComponent');

@Component({
	selector: 'app-shared-create-challenge',
	templateUrl: './create-challenge.component.html',
	styleUrls: ['./create-challenge.component.scss'],

})
export class CreateChallengeComponent implements OnInit {
	constructor(
		public formBuilder: FormBuilder,
		public rdvbandatesService: RdvbandatesService,
		public modalController: ModalController,
		public toastController: ToastController,
		public rdvService: RdvService,
		public navParams: NavParams,
		public authService: AuthService,
		public challengeService: ChallengesService,
		public questionService: QuestionService,
		public moduleService: ModuleService,
	) {}

	public checkoutForm = this.formBuilder.group({
		collaborator: null,
		module: null
	});

	public modulesLoaded = false;
	public isLoading = false;
	public submitted = false;
	public current_user: User = null;

	get f() { return this.checkoutForm.controls; }

	public collaborators: User[] = [];
	public modules: Module[] = [];

	ngOnInit() {}

	ngAfterViewInit() {
		this.current_user = this.authService.user;
		this.collaborators = this.navParams.get('collaborators');
		this.collaborators = this.collaborators.filter((collaborator) => collaborator.id != this.current_user.id );
	}

	async displaySuccessMessage(message) {
		const toast = await this.toastController.create({
			message: message,
			duration: 3000,
			color: "success",
			mode: 'ios',
			position: "top"
		});
		toast.present();
	}

	saveQuestion() {
		this.submitted = true;
		if (this.checkoutForm.invalid) {
			return;
		}

		this.isLoading = true;
		const data = {
			id_challenger: this.checkoutForm.get('collaborator').value.id,
			id_module: this.checkoutForm.get('module').value.id,
			module: this.checkoutForm.get('module').value,
			challenger: this.checkoutForm.get('collaborator').value,
		};

		this.questionService.getRandomQuestionsByModuleId(data.id_module).subscribe((questions) => {
			logger.debug(questions);
			data['questions'] = questions;
			data['challenger'] = false;
			this.dismissModal(data);
		});
	}

	selectCollaborator(e) {
		let collaborator_id = e.detail.value.id;
		this.checkoutForm.patchValue({
			module: null
		});
		this.moduleService.getModulesByUers(this.current_user.id, collaborator_id).subscribe((modules) => {
			this.modules = modules;
			this.modulesLoaded = true;
		});
	}

	onReset() {
		this.submitted = false;
		this.checkoutForm.reset();
	}

	public dismissModal(data = null) {
		this.modalController.dismiss(data);
	}
}
