import { ChallengeQuestion } from "./challenge-question.model";
import { User } from "./user.model";

import * as moment from "moment";
import { Module } from "./module.model";

export class Challenge{
	id:string;
	id_user:string;
	id_challenger:string;
	score:string;
	score_challenger:string;
	created_at:Date;
	updated_at:Date;
	utilisateur:User;
	private _challenges_questions:ChallengeQuestion[];
	private _module:Module;

	@formattedDate()
	formatted_date:string;


	get challenges_questions():ChallengeQuestion[]{
		return this._challenges_questions
	}

	set challenges_questions(challenges_questions){
		this._challenges_questions =  challenges_questions.map(challenge_question =>{
			return Object.assign(new ChallengeQuestion(),challenge_question);
		});
	}

	get module():Module{
		return this._module;
	}

	set module(module){
		this._module = Object.assign(new Module(),module);
	}
}

export function formattedDate() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) { 
				
				return moment(this.created_at.date).format('DD/MM/YYYY HH:mm');

			 },
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}
