export function formatReponses(reponses){

    let list =  "<ul class='list-reps'>";

	for (let key in reponses) {
		let value = reponses[key];
        let good = '';
        if(value.good == 1){
            good = 'good';
        }
		list +=  `<li class="${good}">${value.nom} </li>`
	}

	list += "</ul>";

	return list;
}