import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';

import { ApiService } from '@http/api.service';
import { JwtService } from '@http/jwt.service';
import { User } from '@models/user.model';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { Auth } from '@models/auth.model';
import { Logger } from '@core/logger.service';
import { Router } from '@angular/router';
import * as Rollbar from 'rollbar';
import { RollbarService } from '../../rollbar';

const log = new Logger('AuthService');

@Injectable()
export class AuthService {
	private currentUserSubject = new BehaviorSubject<User>({} as User);
	public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());




	private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
	public isAuthenticatedObservable = this.isAuthenticatedSubject.asObservable();


	private wantRemember = new BehaviorSubject<boolean>(false);
	public wantRememberObservable = this.wantRemember.asObservable();


	private savedUser = new BehaviorSubject<String>('');
	public savedUserObservable = this.savedUser.asObservable();


	getOldUser(): User {
		let userJson = window.localStorage['old_user'];
		let user = null;
		if (userJson) {
			user = JSON.parse(userJson);
		}
		return user;
	}

	saveOldUser(user: User) {
		window.localStorage['old_user'] = JSON.stringify(user);
	}

	destroyOldAccount() {
		window.localStorage.removeItem('old_user');
	}


	saveUser(user: string,pwd:string) {
		window.localStorage['mail'] = user;
		window.localStorage['pwd'] = pwd;
	}

	getUser() {
		let mail = window.localStorage['mail'];
		let pwd = window.localStorage['pwd'];
		if (mail) {
			return { mail,pwd }
		}
		return {};
	}

	saveRememberMe(remember_me: boolean) {
		window.localStorage['remember_me'] = remember_me;
	}

	getRememberMe() {
		let rem = window.localStorage['remember_me'];
		if (rem) {
			return rem;
		}
		return false;
	}

	getCurrentUser(): User {
		let userJson = window.localStorage['current_user'];
		let user = null;
		if (userJson) {
			user = JSON.parse(userJson);
		}
		return user;
	}

	saveCurrentUser(user: User) {
		window.localStorage['current_user'] = JSON.stringify(user);
	}

	desktroyCurrentUser() {
		window.localStorage.removeItem('current_user');
	}




	constructor(
		@Inject(RollbarService) private rollbar: Rollbar,
		private apiService: ApiService,
		private jwtService: JwtService,
		private router: Router
	) {
		
	}


	get user(): User {
		return this.currentUserSubject.getValue();
	}


	set user(val: User) {
		this.currentUserSubject.next(val);
	}



	// Verify JWT in localstorage with server & load user's info.
	// This runs once on application startup.
	populate() {

		return new Promise((resolve, reject) => {
			log.debug('populate');
			let token = this.jwtService.getToken();

			if (token) {

				return this.apiService.get('/users/me')
					.subscribe(
						data => {
							this.setAuth(
								{
									user: data,
									access_token: token
								}
							);
							resolve(true);
						},
						err => { this.purgeAuth(); this.router.navigate(['/login']); resolve(true) }
					);
			} else {
				log.debug('purge auth');
				// Remove any potential remnants of previous auth states
				//this.purgeAuth();
				resolve(true);
			}
		})
		// If JWT detected, attempt to get & store user's info

	}

	setAuth(auth: Auth) {
		log.debug('set auth');
		// Save JWT sent from server in localstorage
		this.jwtService.saveToken(auth.access_token);
		// Set current user data into observable

		//this.currentUserSubject.next(auth.user);

		let userObj = new User();

		Object.assign(userObj, auth.user);
		this.user = userObj;

		//	this.saveCurrentUser(this.user);
		this.rollbar.configure({
			payload: {
				person: {
					id: this.user.id,
     			 	username: this.user.nom+" "+this.user.prenom,
      				email:  this.user.mail
				}
			}
		});

		

		if (this.getCurrentUser()) {
			this.user = this.getCurrentUser()
		};



		// Set isAuthenticated to true
		this.isAuthenticatedSubject.next(true);
	}

	purgeAuth() {
		log.debug('purge auth');
		// Remove JWT from localstorage
		this.jwtService.destroyToken();
		// Set current user to an empty object
		//this.currentUserSubject.next({} as User);
		this.user = {} as User;
		// Set auth status to false
		this.isAuthenticatedSubject.next(false);

		this.destroyOldAccount();
		this.desktroyCurrentUser();



	}
	revertAccount() {
		this.user = this.getOldUser();
		log.debug(this.user);
		this.destroyOldAccount();
		this.desktroyCurrentUser();
		return true;
	}

	connectHasUser(user: User) {
		log.debug('connectHasUser');

		this.saveOldUser(this.user);
		this.saveCurrentUser(user);

		this.user = user;


		return true;
	}




	attemptAuth(credentials): Observable<User> {


		return this.apiService.post('/auth/login', credentials)
			.pipe(map(
				data => {
					this.setAuth(data);
					let userObj = new User();

					Object.assign(userObj, data.user);
					return userObj;
				}
			));
	}


	public isAuthenticated() {
		return this.isAuthenticatedSubject.getValue();
	}

}