import { Pipe, PipeTransform } from '@angular/core';
import {FileService} from '@core/http';
import {FileTypes} from '@helpers/file-types-enums';

type UrlType = 'download' | 'raw';

@Pipe({
  name: 'fileUrl'
})
export class FileUrlPipe implements PipeTransform {
  constructor(
    private fileService: FileService,
  ) {
  }

  transform(value: string|null, fileType: keyof typeof FileTypes = 'DOCUMENTS', urlType: UrlType = 'raw' ): string {
    const type: string = this.fileService.types[FileTypes[fileType]];
    switch ( urlType ) {
      case 'download' : return this.fileService.getFileDownload(type, value || '');
      case 'raw' : return this.fileService.getFileRaw(type, value || '');
    }
    return '';
  }
}
