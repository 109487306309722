import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Documents } from '@models/documents.model';
import { AuthService } from '@core/authentication/authentication.service';

const log = new Logger('DocumentsService');


@Injectable()
export class DocumentsService {




	constructor(
		private apiService: ApiService,
		private authSerice:AuthService
	) { }

	private readonly _docs = new BehaviorSubject<Documents[]>([]);
	readonly docs$ = this._docs.asObservable();


	private readonly _user_docs = new BehaviorSubject<Documents[]>([]);
	readonly user_docs$ = this._user_docs.asObservable();

	private readonly _flashforms_docs = new BehaviorSubject<Documents[]>([]);
	readonly flashforms_docs$ = this._flashforms_docs.asObservable();


	get docs(): Documents[] {
		return this._docs.getValue();
	}

	set docs(val: Documents[]) {
		this._docs.next(val);
	}


	get user_docs(): Documents[] {
		return this._user_docs.getValue();
	}
	set user_docs(val: Documents[]) {
		this._user_docs.next(val);
	}

	get flashforms_docs(): Documents[] {
		return this._flashforms_docs.getValue();
	}
	set flashforms_docs(val: Documents[]) {
		this._flashforms_docs.next(val);
	}

	public create(data): Observable<Documents> {
		log.debug('create', data);

		return this.apiService.post("/documents/", data).pipe(map((data) => {
			let doc = new Documents();
			Object.assign(doc, data.data);
			this.docs = [...this.docs, doc];
			return doc;
		}));
	}

	
	public update(data): Observable<Documents> {
		log.debug('update', data);

		return this.apiService.put(`/documents/${data.id}`, data).pipe(map((data) => {
			let doc = new Documents();
			Object.assign(doc, data.data);

			let docs = this.docs.reduce((ds, d) => {

				if (d.id === doc.id) {
					Object.assign(d, doc);
				}
				return ds.concat(d);
			}, []);

			this.docs = [...docs];

			return doc;
		}));
	}


	public getById(id): Observable<Documents> {
		log.debug('getById',id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/documents/${id}`).pipe(
				map((data) => {
					let doc = new Documents();
					Object.assign(doc, data);
					return doc;
				})).subscribe((data)=>{resolve(data)});
		})
		return (from(promise) as Observable<Documents>);
	}

	public getAll(): Observable<Documents[]> {
		log.debug('getAll');

		if (this.docs.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/documents').pipe(
					map((data) => data.documents.map((data) => {
						let doc = new Documents();
						Object.assign(doc, data);
						return doc;
					}))).subscribe((data) => {
						this.docs = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<Documents[]>);

		} else {

			return this.docs$;
		}

	}

	public getAllByUserId(userId:string){
		log.debug('getAllByUserId');

		if (this.user_docs.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get(`/documents/user/${userId}`).pipe(
					map((data) =>  data.documents.map((data) => {
						let doc = new Documents();
						Object.assign(doc, data);
						return doc;
					}))).subscribe((data) => {
						this.user_docs = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<Documents[]>);
		} else {

			return this.user_docs$;
		}
	}

	public delete(id):Observable<boolean>
	{
		log.debug('delete', id);
		return this.apiService.delete(`/documents/${id}`).pipe(map((data) => {
			this.docs = this.docs.filter(item => item.id != id);
			return true;
		}));
	}
	public like(id_user,id_doc):Observable<any>{

		return this.apiService.get(`/documents/like/${id_user}/${id_doc}`).pipe(map((data) => {
			return data;
		}));
	}

	public viewDocument(id):Observable<boolean>
	{
		let current_user = this.authSerice.user;
		let data = {
			id_user : current_user.id,
		}
		return this.apiService.put(`/documents/view/${id}`,data).pipe(map((data) => {
			return true;
		}));

	}

	public notify(id):Observable<boolean>{
		log.debug('notify', id);
		return this.apiService.get(`/documents/notify/${id}`).pipe(map((data) => {
			return true;
		}));
	}
}