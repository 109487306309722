import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Classroom } from '@models/classroom.model';
import { Challenge } from '@models/challenge.model';

const log = new Logger('ChallengesService');


@Injectable()
export class ChallengesService {

	constructor(
		private apiService: ApiService
	) { }

	private readonly _user_challenges = new BehaviorSubject<Challenge[]>([]);
	readonly user_challenges$ = this._user_challenges.asObservable();


	private readonly _user_challenged = new BehaviorSubject<Challenge[]>([]);
	readonly user_challenged$ = this._user_challenged.asObservable();


	get user_challenged(): Challenge[] {
		return this._user_challenged.getValue();
	}

	set user_challenged(val: Challenge[]) {
		this._user_challenged.next(val);
	}

	get user_challenges(): Challenge[] {
		return this._user_challenges.getValue();
	}

	set user_challenges(val: Challenge[]) {
		this._user_challenges.next(val);
	}

	public create(data): Observable<Challenge> {
		log.debug('create', data);

		return this.apiService.post("/challenges/", data).pipe(map((data) => {
			let challenge = new Challenge();
			Object.assign(challenge, data.data);
			this.user_challenges = [...this.user_challenges, challenge];
			return challenge;
		}));
	}

	public update(data): Observable<Challenge> {
		log.debug('update', data);

		return this.apiService.put(`/challenges/${data.id}`, data).pipe(map((data) => {
			let challenge = new Challenge();
			Object.assign(challenge, data.data);

			let challenges = this.user_challenged.reduce((ds, d) => {

				if (d.id === challenge.id) {
					Object.assign(d, challenge);
				}
				return ds.concat(d);
			}, []);

			this.user_challenged = [...challenges];

			return challenge;
		}));
	}

	public getById(id): Observable<Challenge> {
		log.debug('getById', id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/challenges/${id}`).pipe(
				map((data) => {
					let challenge = new Challenge();
					Object.assign(challenge, data);
					return challenge;
				})).subscribe((data) => { resolve(data) });
		})
		return (from(promise) as Observable<Challenge>);
	} 

	public getAllByUserId(userId:string){
		log.debug('getAllByUserId');

		this.user_challenged = [];
		this.user_challenges = [];
		
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/challenges/user/${userId}`).subscribe((data) => {
					log.debug(data);
					

					
					data.challenges.map((data) => {
						let challenge = Object.assign(new Challenge(),data);
						this.user_challenges= [...this.user_challenges,challenge];
					});

					data.challenged.map((data) => {
						let challenge = Object.assign(new Challenge(),data);
						this.user_challenged= [...this.user_challenged,challenge];
					});

					resolve(data);
				});
		});
		return (from(promise) as Observable<Challenge[]>);
	}
}