import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { SharedModule } from "../../shared.module";
import { AccordionComponent } from "./accordion/accordion";
import { AddForumQuestionComponent } from "./modals/add-forum-question/add-forum-question.component";
import { CreateChallengeComponent } from "./modals/create-challenge/create-challenge.component";
@NgModule({
	declarations: [
		AddForumQuestionComponent,
		AccordionComponent,
		CreateChallengeComponent
	],
	exports: [
		AddForumQuestionComponent,
		AccordionComponent,
		CreateChallengeComponent,
		

	],
	entryComponents: [AddForumQuestionComponent,AccordionComponent,CreateChallengeComponent],
	providers: [],
	imports: [
		ReactiveFormsModule,
		SharedModule,
		CommonModule,
		IonicModule,
		FormsModule,
	],
})
export class SharedComponentModule { }
