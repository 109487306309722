import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from "rxjs/operators";

import { JwtService } from "@http/jwt.service";

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
	constructor(private jwtService: JwtService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		const headersConfig = {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		};

		const token = this.jwtService.getToken();

		if (token) {
			headersConfig['Authorization'] = `Token ${token}`;
		}


		const request = req.clone({ setHeaders: headersConfig });
		return next.handle(request).pipe(tap((evt)=>{
			if (evt instanceof HttpResponse) {
				if(evt.headers.has('access_token')){
					let token = evt.headers.get('access_token');
					this.jwtService.saveToken(token);
				}
			}
		}));
	}
}