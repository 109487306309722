import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Logger } from "@core/logger.service";
import { ApiService } from "@http/api.service";
import { ToastController } from "@ionic/angular";

const log = new Logger('NotificationService');


@Injectable()
export class NotificationService {



	constructor(
		private apiService: ApiService,
		private router: Router,
		private toastController: ToastController
	) { }



	public displayNotification(notification,skipToast) {
		log.debug(notification);

		let title = notification.title

		let content = JSON.parse(notification.content);

		if(skipToast){
			this.redirectToContent( content);
		}else{
			this.displaySuccessMessage(title, content);
		}
	

	}

	async displaySuccessMessage(message, content) {
		const toast = await this.toastController.create({
			message: message,
			duration: 5000,
			color: "primary",
			mode: 'ios',
			position: "top",
			buttons: [
				{
					text: 'Acceder',
					role: 'cancel',
					handler: () => {
						this.redirectToContent(content);
					}
				}
			]
		});
		toast.present();
	}


	public redirectToContent(content) {

		let data = JSON.parse(content.data);


		switch (data.type) {
			case 'module':
				this.router.navigate(['/formations/modules/' + data.id_target]);
				break;
			case 'news':
				this.router.navigate(['/news']);
				break;
			case 'document':
				this.router.navigate(['/medias']);
				break;
			case 'forum':
				this.router.navigate(['/forum']);
				break;	
			case 'create_challenge':
				this.router.navigate(['/community/challenge/'+data.id_target]);
				break;
			case 'update_challenge':
				this.router.navigate(['/community/challenges']);
				break;
		}
	}

	
}