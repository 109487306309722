import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Logger } from '@core/logger.service';
import { UserService } from '@http/user/user.service';
import { ClusterService } from '@http/cluster/cluster.service';
import { EnseigneService } from '@http/enseigne/enseigne.service';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { Cluster } from '@models/cluster.model';
import { MagasinService } from '@http/magasin/magasin.service';
import { RankService } from '@http/rank/rank.service';
import { CategoryService } from '@http/category/category.service';
import { Category } from '@models/category.model';
import { MustMatch } from '@helpers/must-match.validator';
import { formatApiErrors } from '@helpers/format-api-errors';

import { AuthService } from 'src/app/core/authentication/authentication.service';

import { Ranks } from '@helpers/ranks-enum';
import { User } from '@models/user.model';
import { Salon } from '@models/salon.model';
import { Enseigne } from '@models/enseigne.model';
import { Magasin } from '@models/magasin.model';
import { ThrowStmt } from '@angular/compiler';

import { PwdValidators } from './pwd-validators';

const logger = new Logger('CreateUserComponent');

@Component({
	selector: 'app-web-create-user',
	templateUrl: './create-user.component.html',
	styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
	constructor(
		private formBuilder: FormBuilder,
		public userService: UserService,
		public magasinService: MagasinService,
		public rankService: RankService,
		public categoryService: CategoryService,
		public modalController: ModalController,
		public toastController: ToastController,
		public navParams: NavParams,
		private auth: AuthService
	) {}

	public isLoading = false;
	public Ranks = Ranks;
	public salons: Salon[] = [];
	public tuteurs: User[] = [];
	public isUser: boolean = false;

	public currentUser: User = null;
	public ranks_select = [];

	public keyword = 'cp';
	public categories: Category[] = [];
	public checkoutForm = this.formBuilder.group(
		{
			user_mail: '',
			user_firstname: '',
			user_lastname: '',
			user_password: [
				'',
				Validators.compose([
					// 1. Password Field is Required
					Validators.required,
					// 2. check whether the entered password has a number
					PwdValidators.patternValidator('digit', { hasNumber: true }),
					// 3. check whether the entered password has upper case letter
					PwdValidators.patternValidator('majuscule', { hasCapitalCase: true }),
					// 4. check whether the entered password has a lower-case letter
					PwdValidators.patternValidator('minuscule', { hasSmallCase: true }),
					// 5. check whether the entered password has a special character
					PwdValidators.patternValidator('special', { hasSpecialCharacters: true }),
					// 6. Has a minimum length of 8 characters
					PwdValidators.patternValidator('length', { hasEnough: true }),
				]),
			],
			user_confirm_password: ['', Validators.required],
			categories: new FormArray([]),
			magasin: [null, Validators.required],
			tuteur: [null],
			enseigne: [0],
			managed_magasins: null,
			rank: [null, Validators.required],
			salon: [0, [Validators.required, Validators.min(1)]],
		},
		{
			validator: MustMatch('user_password', 'user_confirm_password'),
		}
	);

	public asyncOptionsConfigRank = {
		labelField: 'name',
		valueField: 'id',
		searchField: ['name'],
		dropdownDirection: 'down',
		plugins: ['remove_button', 'dropdown_direction'],
		maxItems: 1,
	};

	public asyncOptionsConfigMag = {
		valueField: 'id',
		searchField: ['cp', 'nom'],
		dropdownDirection: 'down',
		plugins: ['remove_button', 'dropdown_direction'],
		maxItems: 1,
		render: {
			item: function (item, escape) {
				return '<div>' + item.nom + ' - ' + item.cp + '</div>';
			},
			option: function (item, escape) {
				return '<div class="option_selectize">' + item.nom + ' - ' + item.cp + '</div>';
			},
		},
	};

	public asyncOptionsConfigTuteur = {
		valueField: 'id',
		searchField: ['prenom', 'nom'],
		dropdownDirection: 'down',
		plugins: ['remove_button', 'dropdown_direction'],
		maxItems: 1,
		render: {
			item: function (item, escape) {
				return '<div>' + item.nom + ' ' + item.prenom + '</div>';
			},
			option: function (item, escape) {
				return '<div class="option_selectize">' + item.nom + ' ' + item.prenom + '</div>';
			},
		},
	};

	public asyncOptionsConfig = {
		valueField: 'id',
		searchField: ['cp', 'nom'],
		dropdownDirection: 'up',
		plugins: ['remove_button', 'dropdown_direction'],
		maxItems: 20,
		render: {
			item: function (item, escape) {
				return '<div>' + item.nom + ' - ' + item.cp + '</div>';
			},
			option: function (item, escape) {
				return '<div class="option_selectize">' + item.nom + ' - ' + item.cp + '</div>';
			},
		},
	};
	public submitted = false;

	public creating = true;
	public isSelf = false;

	public userId = null;
	public user: User;

	public filteredEnseignes: Enseigne[] = [];
	public filteredMagasins: Magasin[] = [];

	get f() {
		return this.checkoutForm.controls;
	}

	ngOnInit() {

		this.checkoutForm.get('rank').valueChanges.subscribe( rank => {
			if( rank == Ranks.RANK_USER ){
				this.isUser = true;
			}else{
				this.isUser = false;
			}
		});

		this.currentUser = this.auth.user;

		let ranks = this.rankService.getAllPublic();
		if (this.currentUser !== null) {
			this.ranks_select = ranks.filter((item) => {
				return item.id < this.currentUser.rank;
			});
		}

		this.userService.getTuteurs().subscribe( data => {
			this.tuteurs = data;
		});

		this.categoryService.categories$.subscribe((categories) => {
			this.categories = categories;

			const formArray = this.checkoutForm.get('categories') as FormArray;

			this.categories.forEach((cluster) => {
				formArray.push(
					new FormGroup({
						id: new FormControl(cluster.id),
						name: new FormControl(cluster.nom),
						checked: new FormControl(false),
					})
				);
			});
		});

		let id = this.navParams.get('id');

		if (this.navParams.get('salons')) {
			this.salons = this.navParams.get('salons');
		}

		if (this.navParams.get('self')) {
			this.isSelf = true;
			this.checkoutForm.get('rank').clearValidators();
			this.checkoutForm.get('rank').updateValueAndValidity();

			this.checkoutForm.get('enseigne').setValidators([Validators.required, Validators.min(1)]);
			this.checkoutForm.get('enseigne').updateValueAndValidity();
		} else {
			this.checkoutForm.get('salon').clearValidators();
			this.checkoutForm.get('salon').updateValueAndValidity();
		}
		if (id) {
			this.userId = id;
			this.creating = false;
			this.checkoutForm.get('user_password').clearValidators();
			this.checkoutForm.get('user_password').updateValueAndValidity();

			this.checkoutForm.get('user_confirm_password').clearValidators();
			this.checkoutForm.get('user_confirm_password').updateValueAndValidity();

			this.userService.getById(id).subscribe((data) => {
				this.user = data as User;

				this.checkoutForm.patchValue({
					user_firstname: this.user.prenom,
					user_lastname: this.user.nom,
					user_mail: this.user.mail,
					rank: this.user.rank,
					tuteur: this.user.id_sup,
					managed_magasins: this.user.managed_magasins.map((magasin) => {
						return magasin.id;
					}),
					magasin: this.user.magasin.id,
					categories: this.categoryService.categories.map((category) => {
						return this.user.categories.find((cat) => cat.id === category.id) ? { checked: true } : null;
					}),
				});
			});
		}
	}

	selectEnseigne(id_enseigne) {
		//this.checkoutForm.patchValue({'enseigne':id_enseigne});
		this.filteredMagasins = this.magasinService.magasins.filter((item) => item.id_enseigne == id_enseigne);
	}

	selectSalon(salon) {
		//this.enseignes = this.enseignes.filter(item => item.id != id);
		salon = JSON.parse(salon);
		this.filteredEnseignes = (salon as Salon).enseignes;
	}

	async displayErrorMessage(message) {
		const toast = await this.toastController.create({
			message: message,
			duration: 5000,
			color: 'danger',
			mode: 'ios',
			position: 'top',
		});
		toast.present();
	}

	public createUser() {
		if (this.checkoutForm.get('rank').value == Ranks.RANK_MANAGER_TUTEUR) {
			this.checkoutForm.get('managed_magasins').setValidators([Validators.required]);
		} else {
			this.checkoutForm.get('managed_magasins').patchValue([]);
			this.checkoutForm.get('managed_magasins').setValidators([]);
		}
		this.checkoutForm.get('managed_magasins').updateValueAndValidity();

		this.submitted = true;

		// stop here if form is invalid
		if (this.checkoutForm.invalid) {
			return;
		}

		this.isLoading = true;

		let data = {
			pwd: this.checkoutForm.get('user_password').value,
			nom: this.checkoutForm.get('user_lastname').value,
			prenom: this.checkoutForm.get('user_firstname').value,
			rank: this.checkoutForm.get('rank').value,
			id_magasin: this.checkoutForm.get('magasin').value,
			categories: this.checkoutForm
				.get('categories')
				.value.filter((i) => i.checked === true)
				.map((category) => {
					return category.id;
				}),
			managed_magasins: this.checkoutForm.get('managed_magasins').value,
		};

		if(data['rank'] == Ranks.RANK_USER){

			if(this.checkoutForm.get('tuteur').value){
				data['id_sup'] = this.checkoutForm.get('tuteur').value;
			}else{
				data['id_sup'] = null;
			}
		}

		if (this.user !== null && this.user != undefined) {
			if (this.user.mail != this.checkoutForm.get('user_mail').value) {
				data['mail'] = this.checkoutForm.get('user_mail').value;
			}
		} else {
			data['mail'] = this.checkoutForm.get('user_mail').value;
		}

		if (this.isSelf) {
			let selected_salon = JSON.parse(this.checkoutForm.get('salon').value);
			data['id_salon'] = selected_salon.id;

			// creation autonome , donc il faut utlisé la route sans token
			this.userService.insert(data).subscribe(
				(user) => {
					this.isLoading = false;
					this.modalController.dismiss(user);
				},
				(err) => {
					logger.debug(err);
					this.isLoading = false;
					const errorsString = formatApiErrors(err.errors);
					this.displayErrorMessage(err.messages['error'] + '</br>' + errorsString);
				}
			);
		} else {
			if (this.creating) {
				this.userService.create(data).subscribe(
					(user) => {
						this.isLoading = false;
						this.modalController.dismiss(user);
					},
					(err) => {
						logger.debug(err);
						this.isLoading = false;
						const errorsString = formatApiErrors(err.errors);
						this.displayErrorMessage(err.messages['error'] + '</br>' + errorsString);
					}
				);
			} else {
				data['id'] = this.userId;
				this.userService.update(data).subscribe(
					(user) => {
						this.isLoading = false;
						this.modalController.dismiss(user);
					},
					(err) => {
						logger.debug(err);
						this.isLoading = false;
						const errorsString = formatApiErrors(err.errors);
						this.displayErrorMessage(err.messages['error'] + '</br>' + errorsString);
					}
				);
			}
		}
	}

	onReset() {
		this.submitted = false;
		this.checkoutForm.reset();
	}
}
