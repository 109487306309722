import { User } from "./user.model";
import { ClassroomsQuestion } from "./classroom-question.model";

import * as moment from "moment";

export class Classroom {
	id: string;
	nom: string;
	start_date: string;
	end_date?: string;
	webinar: string;
	id_module: string;
	classrooms_users: any[];
	utilisateur: User;
	questions: ClassroomsQuestion[];

	@startDate()
	date_deb_format: Date;

	@endDate()
	date_fin_format: Date;

	@startTime()
	start_time: Date;

	@endTime()
	end_time: Date;


	score: string;
	nb_questions: string;

	@displayDate()
	displayDate: string;
}


export function displayDate() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) {


				return 'Du ' + moment(this.start_date).format('DD/MM/YYYY HH:mm') + " <br/> Au " + moment(this.end_date).format('DD/MM/YYYY HH:mm');

			},

		};
		Object.defineProperty(target, name, descriptor);
	}
}

export function startDate() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) { return new Date(this.start_date).toLocaleDateString('fr-FR'); },
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}

export function endDate() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) { return new Date(this.end_date).toLocaleDateString('fr-FR'); },
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}

export function startTime() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) { return new Date(this.start_date).toLocaleTimeString('fr-FR').substr(0, 5); },
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}

export function endTime() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) { return new Date(this.end_date).toLocaleTimeString('fr-FR').substr(0, 5); },
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}