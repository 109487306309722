import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'fileIcon'
})
export class FileIconPipe implements PipeTransform {

	transform(extension: string): string {
		extension = extension.toLowerCase();
		let icon = 'icon_file';

		switch ( extension ) {
			case 'pdf' : icon = 'icon_pdf'; break;
			case 'zip' : icon = 'icon_zip'; break;
			case 'png' :
			case 'raw' :
			case 'bmp' :
			case 'gif' :
			case 'jpg' :
			case 'jpeg' :
			case 'tiff' :
				icon = 'icon_img';
				break;
			case 'avi' :
			case 'divx' :
			case 'mpeg' :
			case 'mpg' :
			case 'mp4' :
			case 'mkv' :
			case 'flv' :
			case 'ogg' :
			case 'webm' :
				icon = 'icon_video';
				break;
		}

		return `assets/static/images/${icon}.png`;
	}

}
