import { Logger } from "@core/logger.service"

const logger = new Logger('formatApiErrors');

export function formatApiErrors(errors){


	if(typeof errors == "string"){
		errors = JSON.parse(errors);
	}
	let tmp =  "<ul class='list-group' >";

	for (let key in errors) {
		let value = errors[key];

		if( typeof value === 'object' ){
			for (let index in value) {
				let msg = value[index];
				tmp +=  `<li class="list-group-item">${index} : ${msg} </li>`;
			}
		}else{
			tmp +=  `<li class="list-group-item">${key} : ${value} </li>`;
		}
	}

	tmp += "</ul>";
	return tmp;
}