import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from "@angular/core";
@Directive({
    selector: `button[loading] , ion-button[loading]`
})
export class ButtonLoadingDirective implements OnChanges,OnInit {
    @Input() loading: boolean;
    @Input() text_button: string = 'Sauvegarder';
	@Input() text_button_saving: string = 'Sauvegarde en cours...';


    public el: ElementRef;

    constructor(el: ElementRef) {
        this.el = el;
    }

    ngOnInit(){
        var aBlock = document.createElement('i');
        aBlock.classList.add('fa', 'fa-spinner', 'fa-spin', 'mR-10', 'loadingText');

        var bBlock = document.createElement("span");
        bBlock.classList.add('loadingText');

        let textnode = document.createTextNode(this.text_button_saving);
        bBlock.appendChild(textnode);

        (this.el.nativeElement as HTMLButtonElement).appendChild(aBlock);
        (this.el.nativeElement as HTMLButtonElement).appendChild(bBlock);

        var cBlock = document.createElement("span");
        cBlock.classList.add('stateText');

        let textnodeC = document.createTextNode(this.text_button);
        cBlock.appendChild(textnodeC);

        (this.el.nativeElement as HTMLButtonElement).appendChild(cBlock);
    }

    get nativeElement(): HTMLButtonElement {
        return this.el.nativeElement;
    }

    ngOnChanges(changes: SimpleChanges): void {
        
        if (changes.loading) {
            if (changes.loading.currentValue) {
                this.nativeElement.disabled = true;
                this.nativeElement.classList.add('loading');
    
            } else if (!changes.loading.currentValue) {
                this.nativeElement.disabled = false;
                this.nativeElement.classList.remove('loading');
    
            }
        }
    
    }
}