import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { News } from '@models/news.model';

const log = new Logger('NewsService');


@Injectable()
export class NewsService {




	constructor(
		private apiService: ApiService
	) { }

	private readonly _news = new BehaviorSubject<News[]>([]);
	readonly news$ = this._news.asObservable();


	private readonly _user_news = new BehaviorSubject<News[]>([]);
	readonly user_news$ = this._user_news.asObservable();

	private readonly _user_news_types = new BehaviorSubject<any>([]);
	readonly user_news_types$ = this._user_news_types.asObservable();


	get news(): News[] {
		return this._news.getValue();
	}

	set news(val: News[]) {
		this._news.next(val);
	}


	get user_news(): News[] {
		return this._user_news.getValue();
	}
	set user_news(val: News[]) {
		this._user_news.next(val);
	}

	get user_news_types(): any {
		return this._user_news_types.getValue();
	}
	set user_news_types(val: any) {
		this._user_news_types.next(val);
	}


	public create(data): Observable<News> {
		log.debug('create', data);

		return this.apiService.post("/actualites/", data).pipe(map((data) => {
			let actu = new News();
			Object.assign(actu, data.data);
			this.news = [...this.news, actu];
			return actu;
		}));
	}

	
	public update(data): Observable<News> {
		log.debug('update', data);

		return this.apiService.put(`/actualites/${data.id}`, data).pipe(map((data) => {
			let actu = new News();
			Object.assign(actu, data.data);
			let news = this.news.reduce((ds, d) => {

				if (d.id === actu.id) {
					Object.assign(d, actu);
				}
				return ds.concat(d);
			}, []);

			this.news = [...news];

			return actu;
		}));
	}


	public getById(id): Observable<News> {
		log.debug('getById',id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/actualites/${id}`).pipe(
				map((data) => {
					let news = new News();
					Object.assign(news, data);
					return news;
				})).subscribe((data)=>{resolve(data)});
		})
		return (from(promise) as Observable<News>);
	}

	public getAll(): Observable<News[]> {
		log.debug('getAll');

		if (this.news.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/actualites').pipe(
					map((data) => data.actualites.map((data) => {
						let actu = new News();
						Object.assign(actu, data);
						return actu;
					}))).subscribe((data) => {
						this.news = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<News[]>);

		} else {

			return this.news$;
		}

	}



	public getAllByUserId(userId:string){
		log.debug('getAllByUserId');

		if (this.user_news.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get(`/actualites/user/${userId}`).pipe(
					map((data) => data.actualites.map((data) => {
						const news = new News();
						Object.assign(news, data);
						return news;
					}))).subscribe((data) => {
						this.user_news = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<News[]>);

		} else {

			return this.user_news$;
		}
	}

	public getAllByUserIdAndType(userId:string,id_type:string){
		log.debug('getAllByUserIdAndType');

		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/actualites/user/${userId}/0/${id_type}`).pipe(
				map((data) => data.actualites.map((data) => {
					const news = new News();
					Object.assign(news, data);
					return news;
				}))).subscribe((data) => {
					this.user_news = [...data];
					resolve(data);
				});
		});
		return (from(promise) as Observable<News[]>);
	}

	public getAllByUserOrderType(userId:string){
		log.debug('getAllByUserOrderType',userId);

		if (this.user_news_types.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get(`/actualites/user/type/${userId}`).pipe(
					map((data) => {
						return data.types;
					})).subscribe((data) => {
						this.user_news_types = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<News[]>);

		} else {

			return this.user_news_types$;
		}
	}


	public delete(id):Observable<boolean>
	{
		log.debug('delete', id);
		return this.apiService.delete(`/actualites/${id}`).pipe(map((data) => {
			this.news = this.news.filter(item => item.id != id);
			return true;
		}));
	}
	
	public notify(id):Observable<boolean>{
		log.debug('notify', id);
		return this.apiService.get(`/actualites/notify/${id}`).pipe(map((data) => {
			return true;
		}));
	}
}