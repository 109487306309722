import { Enseigne } from "./enseigne.model";
import { Category } from "./category.model";

import { formatEnseignes } from '@helpers/format-enseignes';
import { formatCategories } from '@helpers/format-categories';

export class Salon{
	id:string;
	nom:string;
	code:string;
	date_deb:string;
	date_fin?:string;
	box:string;
	webinar:string;
	pwd:string;
	enseignes:Enseigne[];

	@enseignesList()
	enseignesList:string;

	@dateDeb()
	date_deb_format:Date;

	@dateFin()
	date_fin_format:Date;
}

export function enseignesList(){
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
		  get(this: any) { return formatEnseignes(this.enseignes);},
		  set(value: any) { },
		  enumerable: true,
		  configurable: true,
		};
	
		Object.defineProperty(target, name, descriptor);
	  };
}

export function dateDeb(){
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
		  get(this: any) { return new Date(this.date_deb*1000).toLocaleDateString('fr-FR');},
		  set(value: any) { },
		  enumerable: true,
		  configurable: true,
		};
	
		Object.defineProperty(target, name, descriptor);
	  };
}

export function dateFin(){
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
		  get(this: any) { return new Date(this.date_fin*1000).toLocaleDateString('fr-FR');},
		  set(value: any) { },
		  enumerable: true,
		  configurable: true,
		};
	
		Object.defineProperty(target, name, descriptor);
	  };
}