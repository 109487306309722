import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, ReplaySubject, of } from 'rxjs';

import { ApiService } from '../api.service';
import {  from } from 'rxjs';
import {  map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Magasin } from '@models/magasin.model';

const log = new Logger('MagasinService');


@Injectable()
export class MagasinService  {



	
	constructor(
		private apiService: ApiService
	) { }

	private readonly _magasins = new BehaviorSubject<Magasin[]>([]);
	readonly magasins$ = this._magasins.asObservable();


	
	get magasins(): Magasin[] {
		return this._magasins.getValue();
	}
	


	set magasins(val: Magasin[]) {
		this._magasins.next(val);
	}


	public create(data): Observable<Magasin> {
		log.debug('create', data);

		return this.apiService.post("/magasins", data).pipe(map((data) => {
			let magasins = new Magasin();
			Object.assign(magasins, data.data);
			this.magasins = [...this.magasins, magasins];
			return magasins;
		}));
	}
	public delete(id):Observable<boolean>
	{
		log.debug('delete', id);
		return this.apiService.delete(`/magasins/${id}`).pipe(map((data) => {
			this.magasins = this.magasins.filter(item => item.id != id);
			return true;
		}));
	}


	public update(data): Observable<Magasin> {
		log.debug('update', data);

		return this.apiService.put(`/magasins/${data.id}`, data).pipe(map((data) => {
			let magasin = new Magasin();
			Object.assign(magasin, data.data);


			let magasins = this.magasins.reduce((ds, d) => {

				if (d.id === magasin.id) {
					Object.assign(d, magasin);
				}
				return ds.concat(d);
			}, []);

			this.magasins = [...magasins];
			
			return magasin;
		}));
	}


	public getById(id): Observable<Magasin> {
		log.debug('getById', id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/magasins/${id}`).pipe(
				map((data) => {
					let magasin = new Magasin();
					Object.assign(magasin, data);
					return magasin;
				})).subscribe((data) => { resolve(data) });
		})
		return (from(promise) as Observable<Magasin>);
	}

	public getAll(): Observable<Magasin[]> {
		log.debug('getAll');

		if (this.magasins.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/magasins').pipe(
					map((data) => data.magasins.map((data) => {
						let magasin = new Magasin();
						Object.assign(magasin, data);
						return magasin;
					}))).subscribe((data) => {
						this.magasins = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<Magasin[]>);

		} else {

			return this.magasins$;
		}

	}


}