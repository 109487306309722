import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Salon } from '@models/salon.model';
import { Enseigne } from '@models/enseigne.model';

const log = new Logger('SalonsService');


@Injectable()
export class SalonsService {




	constructor(
		private apiService: ApiService
	) { }

	private readonly _salons = new BehaviorSubject<Salon[]>([]);
	readonly salons$ = this._salons.asObservable();

	private readonly _openSalons = new BehaviorSubject<Salon[]>([]);
	readonly openSalons$ = this._salons.asObservable();




	
	get openSalons(): Salon[] {
		return this._openSalons.getValue();
	}



	set openSalons(val: Salon[]) {
		this._openSalons.next(val);
	}


	get salons(): Salon[] {
		return this._salons.getValue();
	}



	set salons(val: Salon[]) {
		this._salons.next(val);
	}


	public create(data): Observable<Salon> {
		log.debug('create', data);

		return this.apiService.post("/salons/", data).pipe(map((data) => {
			let salon = new Salon();
			Object.assign(salon, data.data);
			this.salons = [...this.salons, salon];
			return salon;
		}));
	}

	
	public update(data): Observable<Salon> {
		log.debug('update', data);

		return this.apiService.put(`/salons/${data.id}`, data).pipe(map((data) => {
			let salon = new Salon();
			Object.assign(salon, data.data);

			let salons = this.salons.reduce((ds, d) => {

				if (d.id === salon.id) {
					Object.assign(d, salon);
				}
				return ds.concat(d);
			}, []);

			this.salons = [...salons];

			return salon;
		}));
	}


	public getById(id): Observable<Salon> {
		log.debug('getById',id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/salons/${id}`).pipe(
				map((data) => {
					let salon = new Salon();
					Object.assign(salon, data);
					return salon;
				})).subscribe((data)=>{resolve(data)});
		})
		return (from(promise) as Observable<Salon>);
	}

	public loginSalon(data): Observable<boolean> {
		log.debug('loginSalon',data);
		
		return this.apiService.post(`/auth/login/salon`,data).pipe(map((data) => {
			log.debug(data);

			if(data.salon){
				return true;
			}else{
				return false;
			}
		}));
	}

	public getOpenSalons(): Observable<Salon[]> {
		log.debug('getOpenSalons');
		let promise = new Promise((resolve, reject) => {
			this.apiService.get('/salons/current').pipe(
				map((data) => data.salons.map((data) => {
					let salons = new Salon();
					Object.assign(salons, data);
					return salons;
				}))).subscribe((data) => {
					this.openSalons = [...data];
					resolve(data);
				});
		});
		return (from(promise) as Observable<Salon[]>);
	}
	public getAll(): Observable<Salon[]> {
		log.debug('getAll');

		if (this.salons.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/salons').pipe(
					map((data) => data.salons.map((data) => {
						let salons = new Salon();
						Object.assign(salons, data);
						return salons;
					}))).subscribe((data) => {
						this.salons = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<Salon[]>);

		} else {

			return this.salons$;
		}

	}

	public getEnseignes(id_salon): Observable<Enseigne[]> {
		log.debug('getEnseignes');

		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/salons/${id_salon}/enseignes`).subscribe((data) => {
				resolve(data.enseignes);
			});
		});
		return (from(promise) as Observable<Enseigne[]>);
	}

	public createScore(data):Observable<boolean>
	{
		log.debug('createScore');
		return this.apiService.post(`/salons/score`,data).pipe(map((data) => {
			log.debug(data);
			//this.salons = this.salons.filter(item => item.id != id);
			return true;
		}));
	}

	public duplicate(id_salon):Observable<boolean> {
		log.debug('duplicate salon', id_salon);

		return this.apiService.get(`/salons/${id_salon}/duplicate`).pipe(map((data) => {
			let salon = new Salon();
			Object.assign(salon, data.data);
			this.salons = [...this.salons, salon];
			return true;
		}));
	}

	public delete(id):Observable<boolean>
	{
		log.debug('delete', id);
		return this.apiService.delete(`/salons/${id}`).pipe(map((data) => {
			this.salons = this.salons.filter(item => item.id != id);
			return true;
		}));
	}
}