import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { JwtService } from './jwt.service';
import { catchError } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { AuthService } from '@core/authentication/authentication.service';
import { Router } from '@angular/router';

const logger = new Logger('ApiService');
@Injectable()
export class ApiService {
	constructor(
		private http: HttpClient,
		private router: Router,
		private jwtService: JwtService
	) { }

	private formatErrors(error: any) {
		logger.debug(error);
		if (error.status == 401) {
			this.router.navigate(['/logout']);
		}
		return throwError(error.error);
	}

	get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
		return this.http.get(`${environment.api_url}${path}`, { params })
			.pipe(catchError((err, caught) => {
				return this.formatErrors(err);
			}));
	}

	put(path: string, body: Object = {}): Observable<any> {
		return this.http.put(
			`${environment.api_url}${path}`,
			JSON.stringify(body)
		).pipe(catchError((err, caught) => {
			return this.formatErrors(err);
		}));
	}

	post(path: string, body: Object = {}): Observable<any> {

		return this.http.post(
			`${environment.api_url}${path}`,
			JSON.stringify(body)
		).pipe(catchError((err, caught) => {
			return this.formatErrors(err);
		}));
	}

	delete(path): Observable<any> {
		return this.http.delete(
			`${environment.api_url}${path}`
		).pipe(catchError((err, caught) => {
			return this.formatErrors(err);
		}));
	}
}