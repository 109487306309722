export class Rdvbandates{
	id:string;
	date:string;
	all_day:string;

    @dateString()
	date_string:string;
}

export function dateString() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) {
				let cur_date = new Date(this.date.date);
				
                var cur_date_string =   pad(cur_date.getDate()) + '/' +
                                        pad(cur_date.getMonth() + 1) + '/' +
                                        pad(cur_date.getFullYear()) + ' ' +
                                        pad(cur_date.getHours()) + ':' +
                                        pad(cur_date.getMinutes()) + ':' +
                                        pad(cur_date.getSeconds());

				return cur_date_string;
			},
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}

function pad(number) {
    if ( number < 10 ) {
      return '0' + number;
    }
    return number;
}