import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";

import { Observable } from "rxjs";
import {take,map} from  'rxjs/operators';
import { of } from 'rxjs';
import { AuthService } from "@core/authentication/authentication.service";

import { Logger } from "@core/logger.service";


const log = new Logger('IsSignedInGuard');

@Injectable()
export class IsSignedInGuard implements CanActivate {

    constructor(private _router: Router,private  authService:AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean{
		//return of(true);
		let isAuth =this.authService.isAuthenticated();
		log.debug(isAuth);
		if(isAuth){
			return isAuth;
		}

		log.debug('Not authenticated, redirecting and adding redirect url...');
		this._router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
		return isAuth;
		//return this.authService.isAuthenticated;
    }

}