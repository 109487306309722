export function formatEnseignes(enseignes){

    let list =  "";
	let count_ens: number = 1;
	for (let key in enseignes) {
		if(count_ens%2 == 0){
            list +=  `<BR>`;
        }

		let value = enseignes[key];

		list +=  `<span class="badge-backend enseignes">${value.nom}</span>`;
		count_ens++;
	}

	return list;
}