import { Module } from "./module.model";
import { Reponse } from "./reponse.model";

import { formatReponses } from '@helpers/format-reponses';
import { Typesquestion } from "./typesquestion.model";

export class Question{
	id:string;
	nom:string;
	id_module:string;
	id_type:string;
	id_certif:string;
	private _reponses:Reponse[];
	module:Module;
	types_question:Typesquestion;
	@reponsesList()
	reponsesList:string;

	@idType()
	idType:number;


	get reponses():Reponse[]{
		return this._reponses
	}

	set reponses(reponses){
		this._reponses =  reponses.map(reponse =>{
			return Object.assign(new Reponse(),reponse);
		});
	}
}

export function idType(){
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
		  get(this: any) { return parseInt(this.id_type);},
		  set(value: any) { },
		  enumerable: true,
		  configurable: true,
		};
	
		Object.defineProperty(target, name, descriptor);
	  };
}

export function reponsesList(){
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
		  get(this: any) { return formatReponses(this.reponses);},
		  set(value: any) { },
		  enumerable: true,
		  configurable: true,
		};
	
		Object.defineProperty(target, name, descriptor);
	  };
}