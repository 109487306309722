export enum Ranks{
	RANK_USER=100,
	RANK_TUTEUR=200,
	RANK_MANAGER_TUTEUR=300,
	RANK_MASTER_KAM=400,
	RANK_SALONS=450,
	RANK_FORMATEUR=500,
	RANK_SUPERVISEUR=600,
	RANK_ADMIN=800,
	RANK_SUPER=1000
}
