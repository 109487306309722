import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component,  Input,  OnInit } from '@angular/core';
import {  FormBuilder, Validators} from '@angular/forms';
import { Logger } from '@core/logger.service';

import { ModalController, NavParams, ToastController } from '@ionic/angular';

import { formatApiErrors } from '@helpers/format-api-errors';
import { Category } from '@models/category.model';
import { CategoryService } from '@http/category/category.service';

const logger = new Logger('CarouselComponent');

@Component({
	selector: 'app-web-carousel',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss'],
	providers:[]
	
})
export class CarouselComponent implements OnInit{

	
	constructor(
		public categoryService:CategoryService,
		public formBuilder: FormBuilder,
		public modalController:ModalController,
		public toastController: ToastController,
		public navParams:NavParams,
		){

	}


	public images;
	ngOnInit(){

		
	
	}
}

