import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from '../api.service';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Enseigne } from '@models/enseigne.model';
import { of, from } from 'rxjs';
const log = new Logger('EnseigneService');


@Injectable()
export class EnseigneService {

	private readonly _enseignes = new BehaviorSubject<Enseigne[]>([]);
	readonly enseignes$ = this._enseignes.asObservable();




	constructor(
		private apiService: ApiService
	) { }


	get enseignes(): Enseigne[] {
		return this._enseignes.getValue();
	}


	set enseignes(val: Enseigne[]) {
		this._enseignes.next(val);
	}



	public getAll(): Observable<Enseigne[]> {
		log.debug('getAll');

		if (this._enseignes.getValue().length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/enseignes').pipe(
					map((data) => data.enseignes.map((data) => {
						const enseigne = new Enseigne();
						Object.assign(enseigne, data);
						return enseigne;
					}))).subscribe((data) => {
						this.enseignes = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<Enseigne[]>);

		} else {

			return this.enseignes$;
		}

	}

	public update(data): Observable<Enseigne> {
		log.debug('update', data);

		return this.apiService.put(`/enseignes/${data.id}`, data).pipe(map((data) => {
			let enseigne = new Enseigne();
			Object.assign(enseigne, data.data);


			let enseignes = this.enseignes.reduce((ds, d) => {

				if (d.id === enseigne.id) {
					Object.assign(d, enseigne);
				}
				return ds.concat(d);
			}, []);

			this.enseignes = [...enseignes];
			
			return enseigne;
		}));
	}

	public delete(id):Observable<boolean>
	{
		log.debug('delete', id);
		return this.apiService.delete(`/enseignes/${id}`).pipe(map((data) => {
			this.enseignes = this.enseignes.filter(item => item.id != id);
			return true;
		}));
	}

	public getById(id): Observable<Enseigne> {
		log.debug('getById', id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/enseignes/${id}`).pipe(
				map((data) => {
					let enseigne = new Enseigne();
					Object.assign(enseigne, data);
					return enseigne;
				})).subscribe((data) => { resolve(data) });
		})
		return (from(promise) as Observable<Enseigne>);
	}

	public create(data): Observable<Enseigne> {
		log.debug('create', data);

		return this.apiService.post("/enseignes", data).pipe(map((data) => {
			let enseigne = new Enseigne();
			Object.assign(enseigne, data.data);
			//this._enseignes.next(this._enseignes.getValue().push(enseigne));
			this.enseignes = [...this.enseignes, enseigne];
			return enseigne;
		}));
	}

}