import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Logger } from '@core/logger.service';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { formatApiErrors } from '@helpers/format-api-errors';

import { DomSanitizer} from '@angular/platform-browser';
import { FileService } from '@http/files/file.service';

const logger = new Logger('ViewVideoComponent');

@Component({
	selector: 'app-web-view-video',
	templateUrl: './view-video.component.html',
	styleUrls: ['./view-video.component.scss'],

})
export class ViewVideoComponent implements OnInit {


	constructor(
		public formBuilder: FormBuilder,
		public modalController: ModalController,
		public toastController: ToastController,
        public fileService:FileService,
        public domSanitizer:DomSanitizer,
		public navParams: NavParams
	) {

	}

    public url_video = null;

	@Input() video: any;
    @Input() type: any;
	ngOnInit() {
        this.video = this.navParams.get('video');
        this.type = this.navParams.get('type');

        var current_url = this.fileService.getFileRaw(this.fileService.types[this.type],this.video);
        this.url_video = this.domSanitizer.bypassSecurityTrustResourceUrl(current_url);


		var elem = document.getElementsByTagName('video')[0];
		elem.requestFullscreen();
		
	}
}