import { Category } from "./category.model";
import { Enseigne } from "./enseigne.model";
import { Magasin } from "./magasin.model";

export class Module{
	id:string;
	nom:string;
	color:string;
	description:string;
	created_at:Date;
	updated_at:[];
	folder:string;
	time:string;
	isRise:string;
	order:string;
	deleted_at:Date;
	published_at:Date;
	img:string;
	categories:Category[];
	enseignes:Enseigne[];
	magasins:Magasin[];
	folder_mobile:string;
	progress:string;

	@categoriesList()
	categoriesList:string;


	@realFolder()
	realFolder:string;
}


export function categoriesList(){
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
		  get(this: any) { return this.categories.map((category)=>category.nom).join(' - ')},
		  set(value: any) { },
		  enumerable: true,
		  configurable: true,
		};
	
		Object.defineProperty(target, name, descriptor);
	  };
}


export function realFolder(){
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
		  get(this: any) { return this.folder_mobile?this.folder_mobile:this.folder},
		  set(value: any) { },
		  enumerable: true,
		  configurable: true,
		};
	
		Object.defineProperty(target, name, descriptor);
	  };
}