import { Category } from "./category.model";

export class Theme{
	id:string;
	nom:string;
	filter:boolean;
	order:string;
	nb_docs:number;
	created_at:Date;
	updated_at:Date;
	deleted_at:Date;
	categories:Category[];

	@categoriesList()
	categoriesList:string;
}

export function categoriesList(){
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
		  get(this: any) { return this.categories.map((category)=>category.nom).join(' - ')},
		  set(value: any) { },
		  enumerable: true,
		  configurable: true,
		};
	
		Object.defineProperty(target, name, descriptor);
	  };
}