import { ForumResponse } from "./forum-response.model";

export class ForumQuestion{
	id: string;
	nom: string;
	id_user: string;
	id_theme: string;
	theme: string;
	created_at: Date;
	updated_at: Date;
	deleted_at: Date;
	forum_reponses: ForumResponse[];


	@hasResponse()
	hasResponse: boolean;
}

export function hasResponse(){
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) { return this.forum_reponses.length !== 0; },
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}
