import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Logger } from '@core/logger.service';
import { RdvService } from '@http/rdv/rdv.service';
import { RdvbandatesService } from '@http/rdv/rdvbandates.service';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { formatApiErrors } from '@helpers/format-api-errors';
import { Rdvbandates } from '@models/rdvbandates.model';

const logger = new Logger('banDatesComponent');

@Component({
	selector: 'app-web-ban-dates',
	templateUrl: './ban-dates.component.html',
	styleUrls: ['./ban-dates.component.scss'],

})
export class BanDatesComponent implements OnInit {


	constructor(
		public formBuilder: FormBuilder,
		public rdvbandatesService: RdvbandatesService,
		public modalController: ModalController,
		public toastController: ToastController,
		public rdvService: RdvService,
		public navParams: NavParams
	) {

	}
	public isLoading = false;
	public checkoutForm = this.formBuilder.group({
		date: ['', Validators.required],
		all_day: ['']
	});

	public submitted = false;
	private config_datetimepicker;
	get f() { return this.checkoutForm.controls; }
	public ban_date:Rdvbandates;
	@Input() id: any;
	ngOnInit() {

		this.rdvService.getFormContent().subscribe((data) => {
			let cur_data = data.data;

			this.config_datetimepicker = cur_data.config_datetimepicker;

			$('#datetimepicker').datetimepicker({
				minDate: this.config_datetimepicker?.min_date,
				format: 'd/m/Y H:i',
				formatDate: 'd/m/Y',
				formatTime: 'H:i',
				dayOfWeekStart: 1,
				disabledWeekDays: [0, 6],
				defaultTime: '09:00',
				allowTimes: this.config_datetimepicker?.complete_hours,
				disabledDates: this.config_datetimepicker?.day_off,
				onSelectDate: (ct, $i) => {
					var date = new Date(ct);
					var key = date.getFullYear() + '-' +  ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
					
					if(key in this.config_datetimepicker?.rdv_ordered){
						var hours_taken = this.config_datetimepicker?.rdv_ordered[key];
						var hours_available = this.config_datetimepicker?.complete_hours;
						var count_hour = 0;
						hours_taken.forEach( (hour_taken, index) => {
							count_hour++;
							hours_available = jQuery.grep(hours_available, function(value) {
								return value != hour_taken;
							});
		
							if(count_hour == hours_taken.length){
								this.datepicker_redefined(hours_available);
							}
						});
					}else{
						this.datepicker_redefined(this.config_datetimepicker?.complete_hours);
					}
				},
				onSelectTime: (ct, $i) => {
					var cur_date = new Date(ct);
	
					var cur_val = 	this.pad(cur_date.getDate()) + '/' +
									this.pad(cur_date.getMonth() + 1) + '/' +
									this.pad(cur_date.getFullYear()) + ' ' +
									this.pad(cur_date.getHours()) + ':' +
									this.pad(cur_date.getMinutes()) + ':' +
									this.pad(cur_date.getSeconds());
	
					// je met le premiere horaire disponible
					$('#datetimepicker').val(cur_val);
					this.checkoutForm.get('date').setValue(cur_val);
				}
			});
		});
	}

	private datepicker_redefined(hours) {
        
        $('#datetimepicker').datetimepicker({
            minDate: this.config_datetimepicker?.min_date,
            maxDate: this.config_datetimepicker?.max_date,
            format: 'd/m/Y H:i',
            formatDate: 'd/m/Y',
            formatTime: 'H:i',
            dayOfWeekStart: 1,
            disabledWeekDays: [0, 6],
            defaultTime: '09:00',
            allowTimes: hours,
            disabledDates: this.config_datetimepicker?.day_off,
            onSelectDate: (ct, $i) => {
                var date = new Date(ct);
                var key = date.getFullYear() + '-' +  ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
                
                if(key in this.config_datetimepicker?.rdv_ordered){
                    var hours_taken = this.config_datetimepicker?.rdv_ordered[key];
                    var hours_available = this.config_datetimepicker?.complete_hours;
                    var count_hour = 0;
					hours_taken.forEach( (hour_taken, index) => {
                        count_hour++;
                        hours_available = jQuery.grep(hours_available, function(value) {
                            return value != hour_taken;
                        });
    
                        if(count_hour == hours_taken.length){
                            this.datepicker_redefined(hours_available);
                        }
                    });
                }else{
                    this.datepicker_redefined(this.config_datetimepicker?.complete_hours);
                }
            },
			onSelectTime: (ct, $i) => {
				var cur_date = new Date(ct);

				var cur_val = 	this.pad(cur_date.getDate()) + '/' +
								this.pad(cur_date.getMonth() + 1) + '/' +
								this.pad(cur_date.getFullYear()) + ' ' +
								this.pad(cur_date.getHours()) + ':' +
								this.pad(cur_date.getMinutes()) + ':' +
								this.pad(cur_date.getSeconds());

				// je met le premiere horaire disponible
				$('#datetimepicker').val(cur_val);
				this.checkoutForm.get('date').setValue(cur_val);
			}
        });

        // je met le premiere horaire disponible
        var current_date = $('#datetimepicker').val().toString().split(' ');
        $('#datetimepicker').val(current_date[0] + ' ' + hours[0]);
		this.checkoutForm.get('date').setValue(current_date[0] + ' ' + hours[0]);
    }
	
	private pad(number) {
		if ( number < 10 ) {
		  return '0' + number;
		}
		return number;
	}

	async displayErrorMessage(message) {
		const toast = await this.toastController.create({
			message: message,
			duration: 5000,
			color: "danger",
			mode: 'ios',
			position: "top"
		});
		toast.present();
	}

	public createDate() {

		this.submitted = true;

		let datetime_arr = this.checkoutForm.get('date').value.split(' ');
		let date_arr = datetime_arr[0].split('/');

		let data = {
			'date' : date_arr[2] + '/' + date_arr[1] + '/' + date_arr[0] + ' ' + datetime_arr[1],
			'all_day': (this.checkoutForm.get('all_day').value === true) ? '1' : '0'
		};

		this.isLoading = true;

		this.rdvbandatesService.create(data).subscribe(() => {
			this.isLoading = false;
			this.modalController.dismiss();
			
		}, (err) => {
			logger.debug(err);
			this.isLoading = false;
			const errorsString = formatApiErrors(err.errors);
			this.displayErrorMessage(err.messages['error'] + "</br>" + errorsString)
		});
	}
}