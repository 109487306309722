import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/authentication/authentication.service';

@Component({
	selector: 'app-web-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

	constructor(public authService: AuthService, public routeur: Router) { }

	ngOnInit() { }


	logout() {
		this.routeur.navigate(['/logout']);
	}
}
