import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component,  Input,  OnInit } from '@angular/core';
import {  FormBuilder, Validators} from '@angular/forms';
import { Logger } from '@core/logger.service';

import { ModalController, NavParams, ToastController } from '@ionic/angular';

import { formatApiErrors } from '@helpers/format-api-errors';
import { UserService } from '@http/user/user.service';
import { User } from '@models/user.model';

const logger = new Logger('ContactComponent');

@Component({
	selector: 'app-web-contact',
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.scss'],
	
})
export class ContactComponent implements OnInit{


	constructor(
		public userService:UserService,
		public formBuilder: FormBuilder,
		public modalController:ModalController,
		public toastController: ToastController,
		public navParams:NavParams,
		){

	}

	public isLoading = false;

	public creating = true; 
	public submitted = false;

	public show_success = false;

	public newsTypeId = null;
	public checkoutForm = this.formBuilder.group({
		lastname:['',Validators.required],
        firstname:['',Validators.required],
        email:['',Validators.required],
        numero:['',Validators.required],
        sujet:['',Validators.required],
	});

	get f() { return this.checkoutForm.controls; }

	ngOnInit(){
        	
	}

	async displayErrorMessage(message) {
		const toast = await this.toastController.create({
			message: message,
			duration: 5000,
			color: "danger",
			mode: 'ios',
			position: "top"
		});
		toast.present();
	}

	public sendContact(){

		this.submitted = true;
		if (this.checkoutForm.invalid) {
			return;
		}

		let data = {
			'name': this.checkoutForm.get('lastname').value,
			'firstname': this.checkoutForm.get('firstname').value,
			'mail': this.checkoutForm.get('email').value,
			'tel': this.checkoutForm.get('numero').value,
			'sujet': this.checkoutForm.get('sujet').value,
		}

		this.isLoading = true;

		this.userService.contact(data).subscribe(()=>{
			this.isLoading = false;
			this.show_success = true;

			setTimeout(() => {
				this.modalController.dismiss();
			}, 1000);
		},(err)=>{
			logger.debug(err);
			this.isLoading = false;
			const errorsString = formatApiErrors(err.errors);
			this.displayErrorMessage(err.messages['error']+"</br>"+errorsString)
		});
	}

	onReset() {
		this.submitted = false;
		this.checkoutForm.reset();
	}
}