import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Inject, Input, OnInit, Renderer2, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Logger } from '@core/logger.service';
import { DataTableDirective } from 'angular-datatables';

import { Subject } from 'rxjs';
import * as dataTableLang from './langs/fr.json';
import { NavController } from '@ionic/angular';


const logger = new Logger('TableComponent');
@Component({
	selector: 'app-web-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('expanded', style({ opacity: 1, visibility: 'visible', height: "*", padding: "20px" })),
			state('collapsed', style({ opacity: 0, visibility: 'hidden', height: "0", padding: "0px" })),
			transition('expanded <=> collapsed', [animate('.25s ease-in')])
		]),
	],
})
export class TableComponent implements OnInit {


	@ViewChild(DataTableDirective, { static: false })
	private datatableElement: DataTableDirective;

	
	@ViewChild('datatableElement') 
   	private testElement: ElementRef;

	dtTrigger: Subject<any> = new Subject<any>();



	@Input() data: any = null;
	@Input() columns: any = null;

	

	@Input() detailExpand: boolean = false;

	@Input() withActions: boolean = true;

	@Input() parent:any = null;

	constructor(public render:Renderer2,public router:Router,public navController:NavController) {
	}

	dtOptions: DataTables.Settings = {
		pagingType: 'full_numbers',
		pageLength: 10,
		orderClasses: true,
		deferRender: true,
		orderMulti:true,
		order:[0,'asc'],
		stateSave: true,
		ajax:(data,cb,settings)=>{
			return true;
		},
		
		language: (dataTableLang as any).default
	};


	trigger() {
		this.dtTrigger.next();
	}


	toggle() {
		this.detailExpand = !this.detailExpand;
	}
	ngOnDestroy(): void {
		this.dtTrigger.unsubscribe();
	}

	ngOnInit() {
		this.columns.forEach((elm) => {
			if (!elm.defaultContent) {
				elm.defaultContent = '';
			}
			
			if(elm.options){
				Object.entries(elm.options).forEach(([key, value])=>{
					if(!elm[key]){
						elm[key] = value;
					}
				});
			}
		})
		this.dtOptions.columns = this.columns;
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
		let self = this;
		this.render.listen(this.testElement.nativeElement, 'click', (e) => {
		
			if(e.target.hasAttribute('(click)')){
				let func = e.target.getAttribute('(click)');
				logger.debug(func);
				if(self.parent){
					eval('self.parent.'+func);
				}
			}

			if(e.target.hasAttribute('routerlink')){
				let url = e.target.getAttribute('routerlink');
				this.navController.navigateForward(url);
			}
			
		});
	}


	ngOnChanges(changes: SimpleChanges) {
		this.dtOptions.data = changes.data.currentValue;	
		if (this.datatableElement) {
			this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
				delete this.dtOptions.ajax;
				dtInstance.destroy();
				this.dtTrigger.next();
			});
		}

	}

	



}
