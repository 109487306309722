import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from '@core/authentication/authentication.service';
import { Logger } from '@core/logger.service';
import { FileService } from '@http/files/file.service';
import { Device } from '@ionic-native/device/ngx';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { NotificationService } from './shared/services/mobile/notification/notification.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserService } from '@http/user/user.service';

const log = new Logger('AppComponent');
@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		private notificatioService: NotificationService,
		private fileService: FileService,
		public device: Device,
		public fcm:FCM,
		public authService: AuthService,
		private userService:UserService,
		public router:Router
	) {
		this.initializeApp();

		this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			if (this.authService.isAuthenticated()) {
				const currentUser = this.authService.user;
				this.userService.progress(currentUser.id).subscribe((data) => {});
			}
		});
	}

	initializeApp() {
		this.splashScreen.hide();
		this.platform.ready().then(() => {
			this.statusBar.styleBlackTranslucent();
			this.fileService.getConstants();

			this.fcm.onNotification().subscribe(data => {
				if ( data.tap ) {
					const notif = JSON.parse(data.content);
					const dataNotif = JSON.parse(notif.data);
					data.title = dataNotif.title;
					this.notificatioService.displayNotification(data, true);
				} else {
					this.notificatioService.displayNotification(data, false);
				}
			});
		});
	}

	ngOnInit() {


	}


}
