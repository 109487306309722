import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component,  Input,  OnInit } from '@angular/core';
import {  FormBuilder, Validators} from '@angular/forms';
import { Logger } from '@core/logger.service';

import { ModalController, NavParams, ToastController } from '@ionic/angular';

import { formatApiErrors } from '@helpers/format-api-errors';
import { UserService } from '@http/user/user.service';
import { User } from '@models/user.model';

const logger = new Logger('ResetPasswordComponent');

@Component({
	selector: 'app-web-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
	
})
export class ResetPasswordComponent implements OnInit{


	constructor(
		public userService:UserService,
		public formBuilder: FormBuilder,
		public modalController:ModalController,
		public toastController: ToastController,
		public navParams:NavParams,
		){

	}

	public isLoading = false;

	public creating = true; 
	public submitted = false;

	public show_success = false;

	public newsTypeId = null;
	public checkoutForm = this.formBuilder.group({
		
        email:['',Validators.required],
        
	});

	get f() { return this.checkoutForm.controls; }

	ngOnInit(){
        	
	}

	async displayErrorMessage(message) {
		const toast = await this.toastController.create({
			message: message,
			duration: 5000,
			color: "danger",
			mode: 'ios',
			position: "top"
		});
		toast.present();
	}

	public lostPassword(){

		this.submitted = true;
		if (this.checkoutForm.invalid) {
			return;
		}

		let data = {
			
			'mail': this.checkoutForm.get('email').value,
		}

		this.isLoading = true;

		this.userService.resetPassword(data).subscribe(()=>{
			this.isLoading = false;
			this.show_success = true;

			this.modalController.dismiss({success:true});
		},(err)=>{
			logger.debug(err);
			this.isLoading = false;
			//const errorsString = formatApiErrors(err.errors);
			this.displayErrorMessage(err.error)
		});
	}

	onReset() {
		this.submitted = false;
		this.checkoutForm.reset();
	}
}