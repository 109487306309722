import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Typesquestion } from "@models/typesquestion.model";

const log = new Logger('TypesQuestionService');


@Injectable()
export class TypesQuestionService {

	constructor(
		private apiService: ApiService
	) { }

	private readonly _types = new BehaviorSubject<Typesquestion[]>([]);
	readonly types$ = this._types.asObservable();	

    get types(): Typesquestion[] {
		return this._types.getValue();
	}

	set types(val: Typesquestion[]) {
		this._types.next(val);
	}

	public getAll(): Observable<Typesquestion[]> {
		log.debug('getAll');

		if (this.types.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get(`/typesquestions`).pipe(
					map((data) => data.types.map((data) => {
						let type = new Typesquestion();
						Object.assign(type, data);
						return type;
					}))).subscribe((data) => {
						this.types = [ ...data];						
						resolve(data);
					});
			});

			return (from(promise) as Observable<Typesquestion[]>);

		} else {
			return this.types$;
		}

	}
}