import { Component, ViewChild, OnInit, Input, Renderer2 } from '@angular/core';

import { EventsService } from 'src/app/shared/services/shared/events.service';

/**
 * Generated class for the AccordionComponent component.
 *
 * See https://angular.io/docs/ts/latest/api/core/index/ComponentMetadata-class.html
 * for more info on Angular Components.
 */
@Component({
	selector: 'accordion',
	templateUrl: 'accordion.html',
	styleUrls:['accordion.scss']
})
export class AccordionComponent implements OnInit {

	@Input('accordionExapanded') accordionExapanded: boolean = false;
	@ViewChild("cc") cardContent: any;
	@Input('title') title: string;

	icon: string = "caret-down-outline";

	constructor(public renderer: Renderer2, public events: EventsService) {
		
	}

	ngOnInit() {
		//console.log(this.accordionExapanded);
		//this.renderer.setStyle(this.cardContent.el, "webkitTransition", "max-height 500ms, padding 500ms");
		//this.initAccordion();
		
		this.collapseAllEvent();

		if(!this.accordionExapanded){
			this.icon = "caret-up-outline";
			
		}
	}

	initAccordion() {

		if (!this.accordionExapanded) {
			this.collapse();
			
			return;
		}

		this.expand();
		this.icon = "caret-up-outline";
	}

	toggleAccordion() {

	
		let tempAccordionExapanded = this.accordionExapanded;
		this.events.sendMessage('collapseAll');

		if (tempAccordionExapanded) {
			return this.collapse();
		}

		this.expand();
	}

	public setCollapseMeta() {
		this.accordionExapanded = false;
		this.icon = "caret-down-outline";
	}

	public setExpandMeta() {
		this.accordionExapanded = true;
		this.icon = "caret-up-outline";
	}

	private collapseAllEvent() {
		this.events.getMessage().subscribe((data) => {
			//console.log(data);
			if (data == 'collapseAll') {
				this.collapse();
			}

		});
	}

	private collapse() {
		this.renderer.setStyle(this.cardContent.el, "max-height", "0px");
		//this.renderer.setStyle(this.cardContent.el, "padding", "0px");
		this.setCollapseMeta();
	}

	private expand() {
		this.renderer.setStyle(this.cardContent.el, "max-height", "1500px");
		//this.renderer.setStyle(this.cardContent.el, "padding", "10px");
		//this.renderer.setStyle(this.cardContent.el, "margin-left", "40px");
		this.setExpandMeta();
	}

}