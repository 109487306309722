import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  constructor() {
    moment.locale('fr');
  }

  transform(value: string, format = 'll' ): string {
    return moment(value).format(format);
  }

}
