import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, ReplaySubject, of } from 'rxjs';

import { ApiService } from '../api.service';
import {  from } from 'rxjs';
import {  map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';

import { Notification } from '@models/notification.model';

const log = new Logger('NotificationService');


@Injectable()
export class NotificationService  {



	
	constructor(
		private apiService: ApiService
	) { }

	private readonly _notifications = new BehaviorSubject<Notification[]>([]);
	readonly notifications$ = this._notifications.asObservable();


	
	get notifications(): Notification[] {
		return this._notifications.getValue();
	}
	


	set notifications(val: Notification[]) {
		this._notifications.next(val);
	}


	
	public  read(notification:Notification){
		log.debug('read', notification);

		notification.read_at = new Date().toLocaleString('en-ZA').replace(',','');
		return this.apiService.put(`/notifications`, notification).pipe(map((data) => {
			return data.data;
		}));
		
	}
	public getByIdUser(idUser): Observable<Notification[]> {
		log.debug('getByIdUser');

		if (this.notifications.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/notifications/user/'+idUser).pipe(
					map((data) => data.notifications.map((data) => {
						let notification = new Notification();
						Object.assign(notification, data);
						return notification;
					}))).subscribe((data) => {
						this.notifications = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<Notification[]>);

		} else {

			return this.notifications$;
		}

	}

	public userHasNotifications(){
		if(this.notifications.length == 0){
			return false;
		}else{
			if(this.notifications[0]?.read_at == null){
				return true;
			}
		}

	

		return false;
	}
	

}