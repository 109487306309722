export class Recording {
	"recordID": string;
	"meetingID": string;
	"internalMeetingID": string;
	"name": string;
	"published": string;
	"startTime": Date;
	"endTime": Date;
	"participants": string;
	"rawSize": string;
	"playback": PlaybackRecording;
	"duration": RecordingDuration;
	"size": string;

	@previewImages()
	images:string;

	@createDateFormatted()
	createDateFormatted:string;

	@dureationFormatted()
	dureationFormatted:string;
}


export function createDateFormatted() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) { 
				let tmpDate = this.startTime.date.split('.')[0];
				var arr = tmpDate.split(/[- :]/),
				date = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]).toLocaleString("fr-FR");
				return date;
			},
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}



export function previewImages() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) { 
				return JSON.stringify(this.playback.preview.images.image);
			},
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}

export function dureationFormatted() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) { 
				/*let tmpDate = this.startTime.date.split('.')[0];
				var arr = tmpDate.split(/[- :]/),
				date = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]).toLocaleString("fr-FR");
				return date;*/
				let date = '';

				if(this.duration.h != '0'){
					date += this.duration.h+"h";
				}
				date += this.duration.i+"m";
				date += this.duration.s+"s";
				return date;
			},
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}


export class RecordingDuration {
	"y": number;
	"m": number;
	"d": number;
	"h": number;
	"i": number;
	"s": number;
	"f": number;
	"weekday": number;
	"weekday_behavior": number;
	"first_last_day_of": number;
	"invert": boolean
	"days": number;
	"special_type": number;
	"special_amount": number;
	"have_weekday_relative": boolean;
	"have_special_relative": boolean;
}

export class PlaybackRecording {
	"type": string;
	"url": string;
	"processingTime": string;
	"length": string;
	"size": string;
	"preview": {
		"images": {
			"image": string[];
		}
	}
}