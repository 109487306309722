import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@core/authentication/authentication.service';
import { Logger } from '@core/logger.service';
import { NotificationService } from '@http/notifications/notification.service';
import { Notification } from '@models/notification.model';
import { UserService } from '@http/user/user.service';
import { filter } from 'rxjs/operators';


const log = new Logger('FrontNavbarComponent');
@Component({
	selector: 'app-web-front-navbar',
	templateUrl: './front-navbar.component.html',
	styleUrls: ['./front-navbar.component.scss'],
})
export class FrontNavbarComponent implements OnInit {

	constructor(
		public authService: AuthService,
		public routeur: Router,
		public notificationService:NotificationService,
		private userService:UserService,
		public router:Router) { 
		}
	public searchText:string;
	public nbNotifs = 0;
	ngOnInit() {

		let current_user = this.authService.user;
		this.notificationService.getByIdUser(current_user.id).subscribe(()=>{
			this.nbNotifs = Math.min(this.notificationService.notifications.length,5);
			log.debug(this.notificationService.notifications.length);
		});	
	}

	onEnter(search_text) {
		this.goSearch(search_text);
	}

	goSearch(search_text){
		this.routeur.navigate(['/search'], { queryParams: { search: search_text }});
	}

	logout() {
		this.routeur.navigate(['/logout']);
	}

	goToTarget(event,notification:Notification){

		if(notification.read_at == null){
			this.notificationService.read(notification).subscribe((data)=>{

			});
		}
		event.preventDefault();
		switch (notification.content.type) {
			case 'module':
				this.router.navigate(['/formations/modules/' + notification.content.id_target]);
				break;
			case 'news':
				this.router.navigate(['/news']);
				break;
			case 'document':
				this.router.navigate(['/medias']);
				break;
			case 'updata_challenge':
					this.router.navigate(['/forum']);
					break;	
			case 'create_challenge':
				this.router.navigate(['/challenge/'+notification.content.id_target]);
				break;		
			case 'update_challenge':
				this.router.navigate(['/forum']);
				break;
		}
	}
}
