import { NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { Platform } from '@ionic/angular';

import { Device } from '@ionic-native/device/ngx';

import { environment } from '@environments/environment';
import {DeviceDetectorService} from "ngx-device-detector";

const mobileRoutes: Routes = [
	{ path: '', loadChildren: () => import('./pages/mobile/mobile.page.module').then((m) => m.MobilePageModule) },
	//{ path: '', loadChildren: () => import('./platform/mobile/mobile.module').then(m => m.MobileModule) },
	//{ path: 'backend', loadChildren: () => import('./pages/backend/backend.page.module').then(m => m.BackendPageModule) },
	{ path: '**', redirectTo: '404' },
];

const webRoutes: Routes = [
	{ path: '', loadChildren: () => import('./pages/web/web.page.module').then((m) => m.WebPageModule) },
	{ path: 'backend', loadChildren: () => import('./pages/backend/backend.page.module').then((m) => m.BackendPageModule) },
	{ path: '**', redirectTo: '404' },
];

@NgModule({
	imports: [
		//RouterModule.forRoot([])
		RouterModule.forRoot(webRoutes, { preloadingStrategy: PreloadAllModules }),
		RouterModule.forRoot(mobileRoutes, { preloadingStrategy: PreloadAllModules })
	],
	providers: [Device],
	exports: [RouterModule],
})
export class AppRoutingModule {
	constructor(
		private router: Router,
		private _DeviceDetectorService: DeviceDetectorService
	) {
		if( this._DeviceDetectorService.isDesktop() ){
			this.router.resetConfig(webRoutes);
		}else{
			this.router.resetConfig(mobileRoutes);
		}
	}
}
