import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";

import { Observable } from "rxjs";
import {take,map} from  'rxjs/operators';
import { of } from 'rxjs';
import { AuthService } from "@core/authentication/authentication.service";

import { Logger } from "@core/logger.service";
import { Ranks } from "@helpers/ranks-enum";


const log = new Logger('IsAdministrateurGuard');

@Injectable()
export class IsAdministrateurGuard implements CanActivate {

    constructor(private _router: Router,private  authService:AuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean{
		//return of(true);

		let user = this.authService.user;

		if(parseInt(user.rank) >= Ranks.RANK_SUPERVISEUR){
			return true;
		}else{
			this._router.navigate(['/backend/dashboard'], {  replaceUrl: true });
			return false;
		}
    }

}