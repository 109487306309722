import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Typesquestion } from "@models/typesquestion.model";
import { Log } from '@models/log.model';
import { environment } from '@environments/environment';

const log = new Logger('LoggerService');


@Injectable()
export class LoggerService {

	constructor(
		private apiService: ApiService
	) { }

	private readonly _logs = new BehaviorSubject<Log[]>([]);
	readonly logs$ = this._logs.asObservable();	

    get logs(): Log[] {
		return this._logs.getValue();
	}

	set logs(val: Log[]) {
		this._logs.next(val);
	}

	public getAll(): Observable<Log[]> {
		log.debug('getAll');

		if (this.logs.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get(`/logs`).pipe(
					map((data) => data.types.map((data) => {
						let type = new Typesquestion();
						Object.assign(type, data);
						return type;
					}))).subscribe((data) => {
						this.logs = [ ...data];						
						resolve(data);
					});
			});

			return (from(promise) as Observable<Log[]>);

		} else {
			return this.logs$;
		}

	}


	public insert(data){
		navigator.sendBeacon(environment.api_url+'/logger/insert',JSON.stringify(data))
	}

	public create(data): Observable<Log> {
		log.debug('create', data);

		return this.apiService.post("/logger", data).pipe(map((data) => {
			let log = new Log();
			Object.assign(log, data.data);
			this.logs = [...this.logs, log];
			return log;
		}));
	}
}