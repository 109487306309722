import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Module } from "@models/module.model";
import { Question } from "@models/question.model";

const log = new Logger('questionService');


@Injectable()
export class QuestionService {

	constructor(
		private apiService: ApiService
	) { }

	private readonly _questions = new BehaviorSubject<Question[]>([]);
	readonly questions$ = this._questions.asObservable();

	private readonly _module = new BehaviorSubject<Module>({} as Module);
	readonly module$ = this._module.asObservable();


	get questions(): Question[] {
		return this._questions.getValue();
	}

	set questions(val: Question[]) {
		this._questions.next(val);
	}

	get module(): Module {
		return this._module.getValue();
	}

	set module(val: Module) {
		this._module.next(val);
	}

	public create(data): Observable<Question> {
		log.debug('create', data);

		return this.apiService.post("/questions/", data).pipe(map((data) => {
			let question = new Question();
			Object.assign(question, data.data);
			this.questions = [...this.questions, question];
			return question;
		}));
	}


	public update(data): Observable<Question> {
		log.debug('update', data);

		return this.apiService.put(`/questions/${data.id}`, data).pipe(map((data) => {
			let question = new Question();
			Object.assign(question, data.data);

			let questions = this.questions.reduce((ds, d) => {

				if (d.id === question.id) {
					Object.assign(d, question);
				}
				return ds.concat(d);
			}, []);

			this.questions = [...questions];

			return question;
		}));
	}


	public getById(id): Observable<Question> {
		log.debug('getById', id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/question/${id}`).pipe(
				map((data) => {
					let question = new Question();
					Object.assign(question, data);

					return question;
				})).subscribe((data) => { resolve(data) });
		})
		return (from(promise) as Observable<Question>);
	}

	public getAll(id_module): Observable<Question[]> {
		log.debug('getAll');


		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/questions/${id_module}`).pipe(
				map((data) => data.questions.map((data) => {
					let question = new Question();
					Object.assign(question, data);
					return question;
				}))).subscribe((data) => {
					this.questions = [...data];

					if (this.questions.length > 0) {
						this.module = this.questions[0]['module'];
					}

					resolve(data);
				});
		});

		return (from(promise) as Observable<Question[]>);
	}

	public getAllModuleCertif(id_module, id_certif): Observable<Question[]> {
		log.debug('getAllModuleCertif');

		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/questions/${id_module}/${id_certif}`).pipe(
				map((data) => data.questions.map((data) => {
					let question = new Question();
					Object.assign(question, data);

					return question;
				}))).subscribe((data) => {
					this.questions = [...data];

					resolve(data);
				});
		});

		return (from(promise) as Observable<Question[]>);
	}


	public delete(id): Observable<boolean> {
		log.debug('delete', id);
		return this.apiService.delete(`/questions/${id}`).pipe(map((data) => {
			this.questions = this.questions.filter(item => item.id != id);
			return true;
		}));
	}


	public getRandomQuestionsByModuleId(id_module){
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/questions/random/${id_module}`).pipe(
				map((data) => data.map((data) => {
					let question = new Question();
					Object.assign(question, data);
					return question;
				}))).subscribe((data) => {
					
					resolve(data);
				});
		});

		return (from(promise) as Observable<Question[]>);
	}





}