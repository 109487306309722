import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { BanDatesComponent } from "./modals/ban-dates/ban-dates.component";
import { CreateEnseigneComponent } from "./modals/create-enseigne/create-enseigne.component";
import { CreateMagasinComponent } from "./modals/create-magasin/create-magasin.component";
import { CreateUserComponent } from "./modals/create-user/create-user.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { TableComponent } from "./table/table.component";
import {NgSelectizeModule} from 'ng-selectize';
import { CreateNewsTypeComponent } from "./modals/create-news-type/create-news-type.component";
import { SharedModule } from "../../shared.module";
import { FrontNavbarComponent } from "./front-navbar/front-navbar.component";
import { FrontBottomBarComponent } from "./front-bottombar/front-bottombar.component";
import { CreateDocumentTypeComponent } from "./modals/create-document-type/create-document-type.component";
import { ContactComponent } from "./modals/contact/contact.component";
import { ViewPDFComponent } from "./modals/view-pdf/view-pdf.component";
import { ViewVideoComponent } from "./modals/view-video/view-video.component";
import { ResetPasswordComponent } from "./modals/reset-password/reset-password.component";
import { CreateCategoryComponent } from "./modals/create-category/create-category.component";
import { CreateThemeComponent } from "./modals/create-theme/create-theme.component";

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CarouselComponent } from "./modals/carousel/carousel.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import {IonicModule} from '@ionic/angular';
@NgModule({
	declarations: [
		SidebarComponent,
		NavbarComponent,
		TableComponent,
		CreateEnseigneComponent,
		BanDatesComponent,
		ViewPDFComponent,
		ViewVideoComponent,
		CreateMagasinComponent,
		CreateUserComponent,
		CreateNewsTypeComponent,
		ResetPasswordComponent,
		CreateDocumentTypeComponent,
		CreateCategoryComponent,
		CreateThemeComponent,
		ContactComponent,
		FrontNavbarComponent,
		FrontBottomBarComponent,
		CarouselComponent
	],
	exports: [
		SidebarComponent,
		NavbarComponent,
		TableComponent,
		CreateMagasinComponent,
		CreateEnseigneComponent,
		BanDatesComponent,
		ViewPDFComponent,
		ViewVideoComponent,
		CreateNewsTypeComponent,
		CreateDocumentTypeComponent,
		CreateCategoryComponent,
		CreateThemeComponent,
		ResetPasswordComponent,
		ContactComponent,
		FrontNavbarComponent,
		CreateUserComponent,
		CarouselComponent,
		FrontBottomBarComponent,
	],
	entryComponents: [CreateUserComponent,CreateEnseigneComponent,BanDatesComponent,CreateMagasinComponent,CreateDocumentTypeComponent,CreateCategoryComponent,CreateThemeComponent,ContactComponent,
		CreateNewsTypeComponent,ViewPDFComponent,ViewVideoComponent,ResetPasswordComponent,CarouselComponent],
	providers: [],
	imports: [
		NgbModule,
		ReactiveFormsModule,
		NgSelectizeModule,
		SharedModule,
		CommonModule,
		AutocompleteLibModule,
		FormsModule,
		DataTablesModule,
		IonicModule
	]
})
export class WebComponentModule { }
