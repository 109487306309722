import { Pipe, PipeTransform } from '@angular/core';

type MatcherCallback<T> = (datum: T, query: string) => RegExpMatchArray|boolean;

@Pipe({
  name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {

  transform<T>(values: T[], query: string|null, matcher: MatcherCallback<T> ): T[] {
    if ( query?.length === 0 ) {
      return values;
    }
    return values.filter( value => !query || matcher(value, query || ''));
  }
}
