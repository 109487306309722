import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, } from 'rxjs';

import { ApiService } from '../api.service';
import { elementAt, map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Rank } from '@models/rank.model';

import { of, from } from 'rxjs';
import { Ranks } from '@helpers/ranks-enum';
import { FileUpload } from '@models/files/file-upload.model';
import { environment } from 'src/environments/environment';

const log = new Logger('FileService');


@Injectable()
export class FileService {

	private readonly _types = new BehaviorSubject<any[]>([]);
	readonly types$ = this._types.asObservable();



	private readonly _updated = new BehaviorSubject<any>(0);
	readonly updated$ = this._updated.asObservable();

	constructor(
		private apiService: ApiService,
	
	) { }


	get types(): any[] {
		return this._types.getValue();
	}


	set types(val: any[]) {
		this._types.next(val);
	}

	get updated(): any {
		return this._updated.getValue();
	}


	set updated(val: any) {
		this._updated.next(val);
	}




	public getConstants(): Observable<any[]> {
		log.debug('getConstants');

		if (this._types.getValue().length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/files/constants').subscribe((data) => {
					this.types = data;
					resolve(data);
				});
			});
			return (from(promise) as Observable<any[]>);

		} else {

			return this.types$;
		}

	}



	public async upload(data: FileUpload): Promise<FileUpload> {

		return new Promise((resolve, reject) => {
			this.apiService.post("/files/upload", data).subscribe((data) => {
				resolve(data.data);
			})
		});
	}

	public getExportDownload(filename: string): string {
		return environment.api_url + `/files/export/${filename}`;
	}

	public getFileDownload(type, filename: string): string {
		return environment.api_url + `/files/${type}/${filename}/download`;
	}
	public getFileRaw(type, filename: string): string {
		return environment.api_url + `/files/${type}/${filename}/raw`;
	}

	public async getFileB64(type, filename: string): Promise<string> {
		return new Promise((resolve, reject) => {
			this.apiService.get(`/files/${type}/${filename}/b64`).subscribe((data) => {
				resolve(data.data.file);
			})
		});
	}



}
