import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';

import { ApiService } from '../api.service';
import { User } from '@models/user.model';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';
import { Logger } from '@core/logger.service';

import { Module } from '@models/module.model';
import { News } from '@models/news.model';

const log = new Logger('UserService');

@Injectable()
export class UserService {

	constructor(
		private apiService: ApiService,

	) { }


	private readonly _users = new BehaviorSubject<User[]>([]);
	readonly users$ = this._users.asObservable();

	private readonly _lastModule = new BehaviorSubject<Module>({} as Module);
	readonly lastModule$ = this._lastModule.asObservable();

	private readonly _lastNews = new BehaviorSubject<News>({} as News);
	readonly lastNews$ = this._lastNews.asObservable();

	private readonly _lastSearch = new BehaviorSubject<any>([]);
	readonly lastSearch$ = this._lastSearch.asObservable();

	public progress_current_user = null;

	get users(): User[] {
		return this._users.getValue();
	}
	set users(val: User[]) {
		this._users.next(val);
	}

	get last_module(): Module {
		return this._lastModule.getValue();
	}
	set last_module(val: Module) {
		this._lastModule.next(val);
	}

	get last_news(): News {
		return this._lastNews.getValue();
	}
	set last_news(val: News) {
		this._lastNews.next(val);
	}

	get last_search(): any {
		return this._lastSearch.getValue();
	}
	set last_search(val: any) {
		this._lastSearch.next(val);
	}


	public getByMagasinId(id): Observable<User[]> {
		log.debug('getByMagasinId', id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/users/magasin/${id}`).pipe(
				map((data) => data.map((data) => {
					let user = new User();
					Object.assign(user, data);
					return user;
				}))).subscribe((data) => {
					resolve(data);
				});
		})
		return (from(promise) as Observable<User[]>);
	}

	public getById(id): Observable<User> {
		log.debug('getById', id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/users/${id}`).pipe(
				map((data) => {
					let user = new User();
					Object.assign(user, data);
					return user;
				})).subscribe((data) => { resolve(data) });
		})
		return (from(promise) as Observable<User>);
	}

	public getAll(id: string): Observable<User[]> {
		log.debug('getAll');

		if (this.users.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/users/all/' + id).pipe(
					map((data) => data.users.map((data) => {
						let user = new User();
						Object.assign(user, data);
						return user;
					}))).subscribe((data) => {
						this.users = [...this.users, ...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<User[]>);

		} else {

			return this.users$;
		}

	}

	public create(data): Observable<User> {
		log.debug('create', data);

		return this.apiService.post("/users", data).pipe(map((data) => {
			let user = new User();
			Object.assign(user, data.data);
			this.users = [...this.users, user];
			return user;
		}));
	}



	public insert(data): Observable<User> {
		log.debug('insert', data);

		return this.apiService.post("/users/insert", data).pipe(map((data) => {
			let user = new User();
			Object.assign(user, data.data);
			//this.users = [...this.users, user];
			return user;
		}));
	}


	public update(data): Observable<User> {
		log.debug('update', data);

		return this.apiService.put(`/users/${data.id}`, data).pipe(map((data) => {
			let user = new User();
			Object.assign(user, data.data);
			
			let users = this.users.reduce((ds, d) => {

				if (d.id === user.id) {
					Object.assign(d, user);
				}
				return ds.concat(d);
			}, []);

			this.users = [...users];

			return user;
		}));
	}
	public delete(id): Observable<boolean> {
		log.debug('delete', id);
		return this.apiService.delete(`/users/${id}`).pipe(map((data) => {
			this.users = this.users.filter(item => item.id != id);
			return true;
		}));
	}


	public resetPassword(data): Observable<boolean> {
		log.debug('resetPassword', data);

		return this.apiService.post("/auth/reset_password", data).pipe(map((data) => {
			return true;
		}));
	}


	public getAllInstructors():  Observable<User[]>  {
		log.debug('getAllInstructors');

		let promise = new Promise((resolve, reject) => {
			this.apiService.get('/users/formateurs').pipe(
			map((data) => data.users.map((data) => {
				let user = new User();
				Object.assign(user, data);
				return user;
			}))).subscribe((data) => {
				resolve(data);
			});
		});
		return (from(promise) as Observable<User[]>);
	}

	public getTuteurs():  Observable<User[]>  {
		log.debug('getTuteurs');

		let promise = new Promise((resolve, reject) => {
			this.apiService.get('/users/tuteurs').pipe(
			map((data) => data.users.map((data) => {
				let user = new User();
				Object.assign(user, data);
				return user;
			}))).subscribe((data) => {
				resolve(data);
			});
		});
		return (from(promise) as Observable<User[]>);
	}

	public getLastContent(id:string): Observable<any> {
		log.debug('getLastContent');

		let promise = new Promise((resolve, reject) => {
			this.apiService.get('/users/last_content/'+id).pipe(
				map((data) => {
					let module = new Module();
					Object.assign(module, data['module']);

					let news = new News();
					Object.assign(news, data['actualite']);

					let return_data = [];
					return_data['module'] = module;
					return_data['news'] = news;

					return return_data;
				})).subscribe((data) => {
					this.last_module = data['module'];
					this.last_news = data['news'];

					resolve(data);
				});
		});

		return (from(promise) as Observable<any>);
	}

	public contact(data): Observable<boolean> {
		log.debug('contact', data);

		return this.apiService.post("/users/contact", data).pipe(map((data) => {
			return true;
		}));
	}

	public search(data): Observable<any> {
		log.debug('search', data);

		return this.apiService.post(`/users/search/${data.id_user}`, data).pipe(map((data) => {
			
			this.last_search = data.data;
			return this.last_search;
		}));
	}

	public progress(id_user): Observable<any> {
		log.debug('progress', id_user);

		return this.apiService.get(`/users/progress/${id_user}`).pipe(map((data) => {
			this.progress_current_user = data.progress;
		}));
	}
}