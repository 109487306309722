import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable,} from 'rxjs';

import { ApiService } from '../api.service';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Category } from '@models/category.model';

import { of,from } from 'rxjs';

const log = new Logger('CategoryService');


@Injectable()
export class CategoryService {


	 

	private readonly _categories = new BehaviorSubject<Category[]>([]);
	readonly categories$ = this._categories.asObservable();



	get categories(): Category[] {
		return this._categories.getValue();
	}


	set categories(val: Category[]) {
		this._categories.next(val);
	}


	constructor(
		private apiService: ApiService
	) { }



	public getAll():Observable<Category[]>{
		log.debug('getAll');

		if(this.categories.length == 0){
			let promise = new Promise( (resolve,reject)=>{  this.apiService.get('/categories').pipe(
						map((data)=> data.categories.map((data)=>{
							let category = new Category();
							Object.assign(category,data);
							return category;
					}))).subscribe((data)=>{
						this.categories = [...data];
						resolve(data);
					});
				});
			return (from(promise) as Observable<Category[]>);
			 
		}else{

			return this.categories$;
		}
		
	}

	public getById(id): Observable<Category> {
		log.debug('getById', id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/categories/${id}`).pipe(
				map((data) => {
					let category = new Category();
					Object.assign(category, data);
					return category;
				})).subscribe((data) => { resolve(data) });
		})
		return (from(promise) as Observable<Category>);
	}

	public create(data): Observable<Category> {
		log.debug('create', data);

		return this.apiService.post("/categories/", data).pipe(map((data) => {
			let category = new Category();
			Object.assign(category, data.data);
			this.categories = [...this.categories, category];
			return category;
		}));
	}

	public update(data): Observable<Category> {
		log.debug('update', data);

		return this.apiService.put(`/categories/${data.id}`, data).pipe(map((data) => {
			let category = new Category();
			Object.assign(category, data.data);


			let categories = this.categories.reduce((ds, d) => {

				if (d.id === category.id) {
					Object.assign(d, category);
				}

				return ds.concat(d);
			}, []);

			this.categories = [...categories];
			
			return category;
		}));
	}
}