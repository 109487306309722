import { Injectable } from '@angular/core';
import { Logger } from '@core/logger.service';

const log = new Logger('JwtService');

@Injectable()
export class JwtService {

	getToken(): string {
		let token =  window.localStorage['jwtToken'];
		
		return token;
	}

	saveToken(token: string) {
		
		window.localStorage['jwtToken'] = token;
	}

	destroyToken() {
		window.localStorage.removeItem('jwtToken');
	}

}