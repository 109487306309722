import { Flashform } from "./flashform.model";
import { FlashformReponse } from "./flashform-reponse.model";

import { formatReponses } from '@helpers/format-reponses';
import { Typesquestion } from "./typesquestion.model";

export class FlashformQuestion{
	id:string;
	nom:string;
	id_flashform:string;
	id_type:string;
	reponses:FlashformReponse[];
	flashform:Flashform;
	types_question:Typesquestion;
	@reponsesList()
	reponsesList:string;

	@idType()
	idType:number;
}

export function idType(){
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
		  get(this: any) { return parseInt(this.id_type);},
		  set(value: any) { },
		  enumerable: true,
		  configurable: true,
		};
	
		Object.defineProperty(target, name, descriptor);
	  };
}

export function reponsesList(){
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
		  get(this: any) { return formatReponses(this.reponses);},
		  set(value: any) { },
		  enumerable: true,
		  configurable: true,
		};
	
		Object.defineProperty(target, name, descriptor);
	  };
}