import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonLoadingDirective } from '@directives/button-loading.directive';
import { ClickStopPropagation } from '@directives/click-stop-propagation.directive';
import { VarDirective } from '@directives/var.directive';
import { ModuleExistPipe } from './pipes/module-exist/module-exist.pipe';
import { NotificationService } from './services/mobile/notification/notification.service';
import { EventsService } from './services/shared/events.service';
import { FileUrlPipe } from './pipes/file-url/file-url.pipe';
import { FileIconPipe } from './pipes/file-icon/file-icon.pipe';
import { DateFormatPipe } from './pipes/date-format/date-format.pipe';
import { FilterArrayPipe } from './pipes/filter-array/filter-array.pipe';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
	],
	providers:[
		NotificationService,
		EventsService
	],
	declarations: [
		VarDirective,
		ClickStopPropagation,
		ButtonLoadingDirective,
		ModuleExistPipe,
		FileUrlPipe,
		FileIconPipe,
		DateFormatPipe,
  FilterArrayPipe,
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		ModuleExistPipe,
		VarDirective,
		ClickStopPropagation,
		ButtonLoadingDirective,
		FileUrlPipe,
		FileIconPipe,
		DateFormatPipe,
  FilterArrayPipe,
	]
})
export class SharedModule { }
