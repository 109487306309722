import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Logger } from '@core/logger.service';
import { RdvService } from '@http/rdv/rdv.service';
import { RdvbandatesService } from '@http/rdv/rdvbandates.service';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { formatApiErrors } from '@helpers/format-api-errors';
import { Rdvbandates } from '@models/rdvbandates.model';
import { ForumQuestionService } from '@http/forum/questions/forum-questions.service';
import { AuthService } from '@core/authentication/authentication.service';
import { User } from '@models/user.model';
import {ThemesService} from '@core/http';
import {Theme} from '@models/theme.model';

const logger = new Logger('banDatesComponent');

@Component({
	selector: 'app-shared-add-forum-question',
	templateUrl: './add-forum-question.component.html',
	styleUrls: ['./add-forum-question.component.scss'],

})
export class AddForumQuestionComponent implements OnInit {
	constructor(
		public formBuilder: FormBuilder,
		public rdvbandatesService: RdvbandatesService,
		public modalController: ModalController,
		public toastController: ToastController,
		public rdvService: RdvService,
		public navParams: NavParams,
		public authService: AuthService,
		public forumQuestionService: ForumQuestionService,
		public themesService: ThemesService,
	) {}

	public checkoutForm = this.formBuilder.group({
		id_theme: null,
		title: ['', Validators.required],
	});

	public isLoading = false;
	public submitted = false;

	public current_user: User = null;
	public themes: Theme[] = [];

	get f() { return this.checkoutForm.controls; }

	ngOnInit() {
		this.current_user = this.authService.user;
		this.themesService.getFilters(this.current_user.id).subscribe( themes => this.themes = themes );
	}

	async displaySuccessMessage(message) {
		const toast = await this.toastController.create({
			message: message,
			duration: 3000,
			color: "success",
			mode: 'ios',
			position: "top"
		});
		toast.present();
	}
	saveQuestion() {

		this.submitted = true;
		if (this.checkoutForm.invalid) {
			return;
		}

		this.isLoading = true;

		let data = {
			id_theme: this.checkoutForm.get('id_theme').value,
			nom: this.checkoutForm.get('title').value,
			id_user: this.current_user.id
		};
		this.forumQuestionService.create(data).subscribe((data) => {
			this.displaySuccessMessage('Votre question a bien été ajoutée');
			this.isLoading = false;
			setTimeout(()=>{
				this.modalController.dismiss();
			},500);
		})
	}

	onReset() {
		this.submitted = false;
		this.checkoutForm.reset();
	}

	public dismissModal(){
		this.modalController.dismiss();
	}
}
