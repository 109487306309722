import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Typesquestion } from "@models/typesquestion.model";
import { Log } from '@models/log.model';
import { environment } from '@environments/environment';

const log = new Logger('DevicesService');


@Injectable()
export class DevicesService {

	constructor(
		private apiService: ApiService
	) { }

	

	

	public create(data): Observable<Log> {
		log.debug('create', data);

		return this.apiService.post("/devices", data).pipe(map((data) => {
			let log = new Log();
			Object.assign(log, data.data);
			return log;
		}));
	}
}