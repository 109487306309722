export class Rdv{
	id:string;
	email:string;
	name:string;
	firstname:string;
	themes:string;
	date:Date;
	validation_date:Date;
	id_enseigne:string;
    id_magasin:string;

	@creationDate()
	creation_date:string;
}

export function creationDate() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) {
				var local = new Date(this.date.date);
				return local.toLocaleDateString('fr-FR');
			},
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}