import { Cluster } from "./cluster.model";

export class Enseigne{
	id:string;
	nom:string;
	deleted_at?:Date;
	color?:string;
	reseaux:Cluster[];

	@clusterList()
	clusterList:string;
}

export function clusterList(){
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
		  get(this: any) { return this.reseaux.map((reseau)=>reseau.nom).join(' - ')},
		  set(value: any) { },
		  enumerable: true,
		  configurable: true,
		};
	
		Object.defineProperty(target, name, descriptor);
	  };
}