export class Reponse{
	id:string;
	nom:string;
	good:string;
	id_question:string;
	@checked()
	checked:boolean;

}




export function checked(){
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
		  get(this: any) { return (this.good == "1")?true:false},
		  set(value: any) { (value)?this.good="1":this.good="0" },
		  enumerable: true,
		  configurable: true,
		};
	
		Object.defineProperty(target, name, descriptor);
	  };
}