import { Ranks } from "@helpers/ranks-enum"
import { Category } from "./category.model"
import { Magasin } from "./magasin.model"
import { Rank } from "./rank.model"

export class User {
	created_at?: string;
	deleted_at?: string;
	fav_cat: string;
	id: string;
	id_magasin: string;
	id_sup?: string;
	mail: string;
	nom: string;
	prenom: string;
	rank: string;
	rgpd: string;
	updated_at?: string;
	magasin?:Magasin;
	categories?:Category[];
	managed_magasins?:Magasin[];
	tuteur?:string;
	
	@fullName()
	full_name:string

	@isAdmin()
	isAdmin:boolean
}


export function isAdmin(){
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
		  get(this: any) {  return  (  parseInt(this.rank) >= Ranks.RANK_ADMIN ) },
		  set(value: any) { },
		  enumerable: true,
		  configurable: true,
		};
	
		Object.defineProperty(target, name, descriptor);
	  };
}

export function fullName(){
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
		  get(this: any) { return this.nom + " "+  this.prenom},
		  set(value: any) { },
		  enumerable: true,
		  configurable: true,
		};
	
		Object.defineProperty(target, name, descriptor);
	  };
}