import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/authentication/authentication.service';
import { NewsTypeService } from '@http/news/news-type.service';
import { DocumentsTypeService } from '@http/documents/documents-type.service';

import { ModalController, ToastController } from '@ionic/angular';
import { ContactComponent } from '@components/web/modals/contact/contact.component';

@Component({
	selector: 'app-web-front-bottombar',
	templateUrl: './front-bottombar.component.html',
	styleUrls: ['./front-bottombar.component.scss'],
})
export class FrontBottomBarComponent  {

	constructor(
		public authService: AuthService,
		public routeur: Router,
		public newsTypeService: NewsTypeService,
		public documentsTypeService: DocumentsTypeService,
		private modalController: ModalController,
		public toastController: ToastController,
	) { }

	public types_news = [];

	ngOnInit() { 
		this.loadData();
	}

	private loadData() {
		Promise.all([this.loadNewsTypes(), this.loadDocumentsTypes()]);
	}

	private loadNewsTypes() {
		return new Promise((resolve, reject) => {
			this.newsTypeService.getAll().subscribe((data) => {
				resolve(true);
			});
		});
	}

	private loadDocumentsTypes() {
		return new Promise((resolve, reject) => {
			this.documentsTypeService.getAll().subscribe((data) => {
				resolve(true);
			});
		});
	}

	public async displayContact() {

		const modal = await this.modalController.create({
			component: ContactComponent,
			mode: "ios",
			cssClass: 'browser-modal',
			componentProps:{}
		});


		/* modal.onWillDismiss().then((data) => {

			//retarded check because if modal is close without submit the form it is still sending empty data
			if (data.data) {
				let documentType = data.data as DocumentsType;
				if(id){
					this.displaySuccessMessage(`Le type de document ${documentType.nom} a été modifié`);
				}else{
					this.displaySuccessMessage(`Le type de document ${documentType.nom} a été créé`);
				}
				
			}
		}); */

		return await modal.present();

	}
}
