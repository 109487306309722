import {Directive, HostListener} from "@angular/core";
import { Logger } from "@core/logger.service";
    
const logger = new Logger('ClickStopPropagation');

@Directive({
    selector: "[click-stop-propagation]"
})
export class ClickStopPropagation
{
    @HostListener("click", ["$event"])
    public onClick(event: any): void
    {
		//logger.debug(event);
        event.stopPropagation();
    }
}