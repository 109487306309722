import { NewsType } from "./news-type.model";
import { Category } from "./category.model";
import { Enseigne } from "./enseigne.model";
import { Magasin } from "./magasin.model";

export class News{
	id:string;
	titre:string;
	description:string;
	date_deb:string;
	date_fin?:string;
	img:string;
	file:string;
	url:string;
	time:string;
	id_type:string;
	created_at:Date;
	updated_at:Date;
	deleted_at:Date;
	categories:Category[];
	enseignes:Enseigne[];
	magasins:Magasin[];
	actualites_type:NewsType;
	
	@dateDeb()
	date_deb_format:Date;

	@dateFin()
	date_fin_format:Date;

	@categoriesList()
	categoriesList:string;

}

export function categoriesList(){
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
		  get(this: any) { return this.categories.map((category)=>category.nom).join(' - ')},
		  set(value: any) { },
		  enumerable: true,
		  configurable: true,
		};
	
		Object.defineProperty(target, name, descriptor);
	  };
}

export function dateDeb() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) {
				let date = new Date(this.date_deb*1000).toLocaleDateString("fr-FR");
				
				return date;
			},
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}

export function dateFin() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) {
				let date = new Date(this.date_fin*1000).toLocaleDateString("fr-FR");
				
				return date;
			},
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}