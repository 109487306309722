import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Rdvbandates } from '@models/rdvbandates.model';

const log = new Logger('RdvService');


@Injectable()
export class RdvbandatesService {

	constructor(
		private apiService: ApiService
	) { }

	private readonly _banDates = new BehaviorSubject<Rdvbandates[]>([]);
	readonly banDates$ = this._banDates.asObservable();

	get banDates(): Rdvbandates[] {
		return this._banDates.getValue();
	}

	set banDates(val: Rdvbandates[]) {
		this._banDates.next(val);
	}

	public create(data): Observable<Rdvbandates> {
		log.debug('create', data);

		return this.apiService.post("/rdvbandates/", data).pipe(map((data) => {
			let banDate = new Rdvbandates();
			Object.assign(banDate, data.data);
			this.banDates = [...this.banDates, banDate];
			return banDate;
		}));
	}

	public getAll(): Observable<Rdvbandates[]> {
		log.debug('getAll');

		if (this.banDates.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/rdvbandates').pipe(
					map((data) => data.dates.map((data) => {
						let banDate = new Rdvbandates();
						Object.assign(banDate, data);
						return banDate;
					}))).subscribe((data) => {
						this.banDates = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<Rdvbandates[]>);

		} else {

			return this.banDates$;
		}

	}

	public delete(id):Observable<boolean>
	{
		log.debug('delete', id);
		return this.apiService.delete(`/rdvbandates/${id}`).pipe(map((data) => {
			this.banDates = this.banDates.filter(item => item.id != id);
			return true;
		}));
	}
}