import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { NewsType } from '@models/news-type.model';

const log = new Logger('NewsTypeService');


@Injectable()
export class NewsTypeService {




	constructor(
		private apiService: ApiService
	) { }

	private readonly _newsTypes = new BehaviorSubject<NewsType[]>([]);
	readonly newsTypes$ = this._newsTypes.asObservable();



	get newsTypes(): NewsType[] {
		return this._newsTypes.getValue();
	}



	set newsTypes(val: NewsType[]) {
		this._newsTypes.next(val);
	}


	public create(data): Observable<NewsType> {
		log.debug('create', data);

		return this.apiService.post("/actualitestypes/", data).pipe(map((data) => {
			let newsType = new NewsType();
			Object.assign(newsType, data.data);
			this.newsTypes = [...this.newsTypes, newsType];
			return newsType;
		}));
	}



	public delete(id,replace_id= null):Observable<boolean>
	{
		log.debug('delete', id);
		let url = `/actualitestypes/${id}`;
		if(replace_id){
			url += `/${replace_id}`;
		}

		return this.apiService.delete(url).pipe(map((data) => {
			this.newsTypes = this.newsTypes.filter(item => item.id != id);
			return true;
		}));
	}
	public update(data): Observable<NewsType> {
		log.debug('update', data);

		return this.apiService.put(`/actualitestypes/${data.id}`, data).pipe(map((data) => {
			let newsType = new NewsType();
			Object.assign(newsType, data.data);


			let newsTypes = this.newsTypes.reduce((ds, d) => {

				if (d.id === newsType.id) {
					Object.assign(d, newsType);
				}
				return ds.concat(d);
			}, []);

			this.newsTypes = [...newsTypes];
			
			return newsType;
		}));
	}


	public getById(id): Observable<NewsType> {
		log.debug('getById', id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/actualitestypes/${id}`).pipe(
				map((data) => {
					let newsType = new NewsType();
					Object.assign(newsType, data);
					return newsType;
				})).subscribe((data) => { resolve(data) });
		})
		return (from(promise) as Observable<NewsType>);
	}

	public getAll(): Observable<NewsType[]> {
		log.debug('getAll');

		if (this.newsTypes.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/actualitestypes').pipe(
					map((data) => data.types.map((data) => {
						let newsType = new NewsType();
						Object.assign(newsType, data);
						return newsType;
					}))).subscribe((data) => {
						this.newsTypes = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<NewsType[]>);

		} else {

			return this.newsTypes$;
		}

	}






}