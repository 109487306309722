import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { DocumentsType } from '@models/documents-type.model';

const log = new Logger('DocumentsTypeService');


@Injectable()
export class DocumentsTypeService {




	constructor(
		private apiService: ApiService
	) { }

	private readonly _documentsTypes = new BehaviorSubject<DocumentsType[]>([]);
	readonly documentsTypes$ = this._documentsTypes.asObservable();



	get documentsTypes(): DocumentsType[] {
		return this._documentsTypes.getValue();
	}



	set documentsTypes(val: DocumentsType[]) {
		this._documentsTypes.next(val);
	}


	public create(data): Observable<DocumentsType> {
		log.debug('create', data);

		return this.apiService.post("/documentstypes/", data).pipe(map((data) => {
			let type = new DocumentsType();
			Object.assign(type, data.data);
			this.documentsTypes = [...this.documentsTypes, type];
			return type;
		}));
	}



	public delete(id,replace_id= null):Observable<boolean>
	{
		log.debug('delete', id);
		let url = `/documentstypes/${id}`;
		if(replace_id){
			url += `/${replace_id}`;
		}

		return this.apiService.delete(url).pipe(map((data) => {
			this.documentsTypes = this.documentsTypes.filter(item => item.id != id);
			return true;
		}));
	}
	public update(data): Observable<DocumentsType> {
		log.debug('update', data);

		return this.apiService.put(`/documentstypes/${data.id}`, data).pipe(map((data) => {
			let type = new DocumentsType();
			Object.assign(type, data.data);


			let documentsTypes = this.documentsTypes.reduce((ds, d) => {

				if (d.id === type.id) {
					Object.assign(d, type);
				}
				return ds.concat(d);
			}, []);

			this.documentsTypes = [...documentsTypes];
			
			return type;
		}));
	}

	public getById(id): Observable<DocumentsType> {
		log.debug('getById', id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/documentstypes/${id}`).pipe(
				map((data) => {
					let type = new DocumentsType();
					Object.assign(type, data);
					return type;
				})).subscribe((data) => { resolve(data) });
		})
		return (from(promise) as Observable<DocumentsType>);
	}

	public getAll(): Observable<DocumentsType[]> {
		log.debug('getAll');

		if (this.documentsTypes.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/documentstypes').pipe(
					map((data) => data.types.map((data) => {
						let type = new DocumentsType();
						Object.assign(type, data);
						return type;
					}))).subscribe((data) => {
						this.documentsTypes = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<DocumentsType[]>);

		} else {

			return this.documentsTypes$;
		}
	}
}