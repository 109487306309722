import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Module } from '@models/module.model';
import { ForumQuestion } from '@models/forum-question.model';

let log = new Logger('ForumQuestionService');


@Injectable()
export class ForumQuestionService {




	constructor(
		private apiService: ApiService
	) { }

	private readonly _questions = new BehaviorSubject<ForumQuestion[]>([]);
	readonly questions$ = this._questions.asObservable();

	private readonly _questions_user = new BehaviorSubject<ForumQuestion[]>([]);
	readonly questions_user$ = this._questions_user.asObservable();

	private readonly _open__questions_user = new BehaviorSubject<ForumQuestion[]>([]);
	readonly open_questions_user$ = this._questions_user.asObservable();



	get questions(): ForumQuestion[] {
		return this._questions.getValue();
	}

	set questions(val: ForumQuestion[]) {
		this._questions.next(val);
	}

	get questions_user(): ForumQuestion[] {
		return this._questions_user.getValue();
	}

	set questions_user(val: ForumQuestion[]) {
		this._questions_user.next(val);
	}

	
	get open_questions_user(): ForumQuestion[] {
		return this._open__questions_user.getValue();
	}

	set open_questions_user(val: ForumQuestion[]) {
		this._open__questions_user.next(val);
	}




	public create(data): Observable<ForumQuestion> {
		log.debug('create', data);

		return this.apiService.post("/forumquestions/", data).pipe(map((data) => {
			let question = new ForumQuestion();
			Object.assign(question, data.data);
			this.open_questions_user = [...this.open_questions_user, question];
			return question;
		}));
	}

	
	public update(data): Observable<ForumQuestion> {
		log.debug('update', data);

		return this.apiService.put(`/forumquestions/${data.id}`, data).pipe(map((data) => {
			let question = new ForumQuestion();
			Object.assign(question, data.data);
			let questions = this.questions.reduce((ds, d) => {

				if (d.id === question.id) {
					Object.assign(d, question);
				}
				return ds.concat(d);
			}, []);

			this.questions = [...questions];

			return question;
		}));
	}


	public getById(id): Observable<ForumQuestion> {
		log.debug('getById',id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/forumquestions/${id}`).pipe(
				map((data) => {
					let question = new ForumQuestion();
					Object.assign(question, data);
					return question;
				})).subscribe((data)=>{resolve(data)});
		})
		return (from(promise) as Observable<ForumQuestion>);
	}



	public getAll(): Observable<ForumQuestion[]> {
		log.debug('getAll');

		if (this.questions.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/forumquestions').pipe(
					map((data) => data.questions.map((data) => {
						let question = new ForumQuestion();
						Object.assign(question, data);
						return question;
					}))).subscribe((data) => {
						this.questions = [ ...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<ForumQuestion[]>);

		} else {

			return this.questions$;
		}

	}

	public getAllOpen(id_user): Observable<ForumQuestion[]> {
		log.debug('getAllOpen',id_user);

		if (this.questions.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.questions = [];
				this.apiService.get(`/forumquestions/all/${id_user}`).pipe(
					map((data) => data.questions.map((data) => {
						let question = new ForumQuestion();
						Object.assign(question, data);
						return question;
					}))).subscribe((data) => {
						data.forEach((value:ForumQuestion)=>{
							if(value.hasResponse){
								
								this.questions.push(value);
							}
						})
						
						resolve(data);
					});
			});
			return (from(promise) as Observable<ForumQuestion[]>);

		} else {

			return this.questions$;
		}

	}

	public getAllByUserId(userId:string){
		log.debug('getAllByUserId');

		if (this.questions_user.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.questions_user = [];
				this.open_questions_user = [];
				this.apiService.get(`/forumquestions/user/${userId}`).pipe(
					map((data) => data.questions.map((data) => {
						let question = new ForumQuestion();
						Object.assign(question, data);
						return question;
					}))).subscribe((data) => {
						//log.debug(data);
						data.forEach((value:ForumQuestion)=>{
							if(value.hasResponse){
								this.questions_user.push(value);
							}else{
								this.open_questions_user.push(value);
							}
						})
					//	this.questions_user = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<ForumQuestion[]>);

		} else {

			return this.questions_user$;
		}
	}


	public delete(id):Observable<boolean>
	{
		log.debug('delete', id);
		return this.apiService.delete(`/forumquestions/${id}`).pipe(map((data) => {
			this.questions = this.questions.filter(item => item.id != id);
			return true;
		}));
	}

	public userHasOpenQuestions(){
		return new Promise((resolve,reject)=>{
			this.questions_user.forEach((value)=>{
				if(value.hasResponse){
					resolve(true);
				}
			});
			resolve(false);
		});
	}


}
