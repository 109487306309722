import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component,  Input,  OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Logger } from '@core/logger.service';
import { formatApiErrors } from '@helpers/format-api-errors';
import { ClusterService } from '@http/cluster/cluster.service';
import { EnseigneService } from '@http/enseigne/enseigne.service';
import { MagasinService } from '@http/magasin/magasin.service';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { Magasin } from '@models/magasin.model';

const logger = new Logger('CreateMagasinComponent');

@Component({
	selector: 'app-web-create-magasin',
	templateUrl: './create-magasin.component.html',
	styleUrls: ['./create-magasin.component.scss'],
	
})
export class CreateMagasinComponent implements OnInit{


	constructor(
		public magasinService:MagasinService,
		private formBuilder: FormBuilder,
		public enseigneService: EnseigneService,
		public modalController:ModalController,
		public toastController: ToastController,
		public navParams:NavParams
		){

	}
	public checkoutForm = this.formBuilder.group({
		magasin_name:"",
		enseigne:null,
		address:[null,[Validators.required]],
		city:[null,[Validators.required]],
		cp:[null,[Validators.required]]
	});
	
	public isLoading = false;
	public submitted = false;

	get f() { return this.checkoutForm.controls; }


	public creating = true; 

	public magasinId = null;

	public magasin:Magasin;
	
	
	@Input() id:any;
	ngOnInit(){
		let id = this.navParams.get('id');
		if(id){
			this.magasinId = id;
			this.creating = false;
			this.magasinService.getById(id).subscribe((data) => {
				this.magasin  = (data as Magasin);
				this.checkoutForm.patchValue({
					'magasin_name':data.nom,
					'enseigne':data.id_enseigne,
					'address':data.adresse,
					'city':data.ville,
					'cp':data.cp
				})
			});
		}
		
	}
	async displayErrorMessage(message) {
		const toast = await this.toastController.create({
			message: message,
			duration: 5000,
			color: "danger",
			mode: 'ios',
			position: "top"
		});
		toast.present();
	}



	public createMagasin(){
		this.submitted = true;
		if (this.checkoutForm.invalid) {
			return;
		}
		let id_enseigne = this.checkoutForm.get('enseigne').value;
		let nom = this.checkoutForm.get('magasin_name').value;


		let data = {
			'nom' : nom,
			'id_enseigne':id_enseigne,
			'adresse': this.checkoutForm.get('address').value,
			'ville': this.checkoutForm.get('city').value,
			'cp': this.checkoutForm.get('cp').value,
		};

		if (this.magasin !== undefined && this.magasin.nom == nom) {
			delete data['nom'];
		}

		this.isLoading = true;

		
		if(this.creating){
			this.magasinService.create(data).subscribe((magasin)=>{
				this.isLoading = false;
				this.modalController.dismiss(magasin);
			},(err)=>{
				this.isLoading = false;
				logger.debug(err);
				const errorsString = formatApiErrors(err.errors);
				this.displayErrorMessage(err.messages['error']+"</br>"+errorsString)
			});
		}else {
			data['id'] = this.magasinId;
			this.magasinService.update(data).subscribe((newsType) => {
				this.isLoading = false;
				this.modalController.dismiss(newsType);
			}, (err) => {
				logger.debug(err);
				this.isLoading = false;
				const errorsString = formatApiErrors(err.errors);
				this.displayErrorMessage(err.messages['error'] + "</br>" + errorsString)
			});
		}
		
		
	}

	onReset() {
		this.submitted = false;
		this.checkoutForm.reset();
	}
}