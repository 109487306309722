import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable,} from 'rxjs';

import { ApiService } from '../api.service';
import { elementAt, map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Rank } from '@models/rank.model';

import { of,from } from 'rxjs';
import { Ranks } from '@helpers/ranks-enum';

const log = new Logger('RankService');


@Injectable()
export class RankService {


	 

	private readonly _ranks = new BehaviorSubject<Rank[]>([]);
	readonly ranks$ = this._ranks.asObservable();



	get ranks(): Rank[] {
		return this._ranks.getValue();
	}


	set ranks(val: Rank[]) {
		this._ranks.next(val);
	}


	
	

	constructor(
		private apiService: ApiService
	) { }




	public  getAllPublic(){

		return this.ranks.filter(elm=>elm.public==true)
	}

	public getAll():Observable<Rank[]>{
		

		let ranks = [
			Object.assign(new Rank(),{id:Ranks.RANK_USER,name:"Vendeur",public:true}),
			Object.assign(new Rank(),{id:Ranks.RANK_TUTEUR,name:"Tuteur",public:true}),
			Object.assign(new Rank(),{id:Ranks.RANK_MANAGER_TUTEUR,name:"Manager tuteur",public:true}),
			Object.assign(new Rank(),{id:Ranks.RANK_MASTER_KAM,name:"RANK_MASTER_KAM",public:false}),
			Object.assign(new Rank(),{id:Ranks.RANK_SALONS,name:"Salons",public:true}),
			Object.assign(new Rank(),{id:Ranks.RANK_FORMATEUR,name:"Formateur",public:true}),
			Object.assign(new Rank(),{id:Ranks.RANK_SUPERVISEUR,name:"Superviseur",public:true}),
			Object.assign(new Rank(),{id:Ranks.RANK_ADMIN,name:"Administrateur",public:true}),
			Object.assign(new Rank(),{id:Ranks.RANK_SUPER,name:"RANK_SUPER",public:false}),
		]

		this.ranks = [...this.ranks, ...ranks];
		return of(ranks);
		
	}


	


}