import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Theme } from '@models/theme.model';

const log = new Logger('ThemesService');


@Injectable()
export class ThemesService {




	constructor(
		private apiService: ApiService
	) { }

	private readonly _themes = new BehaviorSubject<Theme[]>([]);
	readonly themes$ = this._themes.asObservable();

	private readonly _filters = new BehaviorSubject<Theme[]>([]);
	readonly filters$ = this._filters.asObservable();


	get themes(): Theme[] {
		return this._themes.getValue();
	}

	set themes(val: Theme[]) {
		this._themes.next(val);
	}

	get filters(): Theme[] {
		return this._filters.getValue();
	}

	set filters(val: Theme[]) {
		this._filters.next(val);
	}


	public create(data): Observable<Theme> {
		log.debug('create', data);

		return this.apiService.post("/themes/", data).pipe(map((data) => {
			const type = new Theme();
			Object.assign(type, data.data);
			this.themes = [...this.themes, type];
			return type;
		}));
	}



	public delete(id,replace_id= null):Observable<boolean>
	{
		log.debug('delete', id);
		let url = `/themes/${id}`;
		if(replace_id){
			url += `/${replace_id}`;
		}

		return this.apiService.delete(url).pipe(map((data) => {
			this.themes = this.themes.filter(item => item.id != id);
			return true;
		}));
	}
	public update(data): Observable<Theme> {
		log.debug('update', data);

		return this.apiService.put(`/themes/${data.id}`, data).pipe(map((data) => {
			let type = new Theme();
			Object.assign(type, data.data);

			let themes = this.themes.reduce((ds, d) => {

				if (d.id === type.id) {
					Object.assign(d, type);
				}
				return ds.concat(d);
			}, []);

			this.themes = [...themes];
			
			return type;
		}));
	}

	public getById(id): Observable<Theme> {
		log.debug('getById', id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/themes/${id}`).pipe(
				map((data) => {
					let type = new Theme();
					Object.assign(type, data);
					return type;
				})).subscribe((data) => { resolve(data) });
		})
		return (from(promise) as Observable<Theme>);
	}

	public getAll(): Observable<Theme[]> {
		log.debug('getAll');

		if (this.themes.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/themes').pipe(
					map((data) => data.themes.map((data) => {
						let type = new Theme();
						Object.assign(type, data);
						return type;
					}))).subscribe((data) => {
						this.themes = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<Theme[]>);

		} else {

			return this.themes$;
		}
	}

	public getFilters(user_id): Observable<Theme[]> {
		log.debug('getFilters');

		if (this.filters.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get(`/themes/filters/${user_id}`).pipe(
					map((data) => data.filters.map((data) => {
						let theme = new Theme();
						Object.assign(theme, data);
						return theme;
					}))).subscribe((data) => {
						this.filters = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<Theme[]>);

		} else {

			return this.filters$;
		}
	}

	public order(themes): Observable<Boolean> {
		log.debug('order', themes);

		return this.apiService.post(`/themes/order`, {"themes":themes}).pipe(map((data) => {

			return true;
		}));
	}
}