import { FlashformQuestion } from "./flashform-question.model";
import { Documents } from "./documents.model";

export class Flashform{
	id:string;
	nom:string;
	id_doc:string;
	id_module:string;
	questions:FlashformQuestion[];
	document:Documents;
}