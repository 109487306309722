import { ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';

export class PwdValidators {
	static patternValidator(type: string, error: ValidationErrors): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
            if (!control.value) {
                // if control is empty return no error
                return null;
            }
            switch(type){
                case 'digit':
                    var regex = /\d/;
                    break;
                case 'majuscule':
                    var regex = /[A-Z]/;
                    break;
                case 'minuscule':
                    var regex = /[a-z]/;
                    break;
                case 'length':
                    var regex = /^.{8,}$/;
                    break;
                case 'special':
                    var regex = /[^\w\s]/gi;
                    break;
            }

            // test the value of the control against the regexp supplied
            const valid = regex.test(control.value);
        
            // if true, return no error (no error), else return error passed in the second parameter
            return valid ? null : error;
		};
	}
}