import { NewsType } from "./news-type.model";
import { Category } from "./category.model";
import { Enseigne } from "./enseigne.model";
import { Magasin } from "./magasin.model";
import { Theme } from "./theme.model";

export class Documents {
	id: string;
	titre: string;
	nom: string;
	link: string;
	ext: string;
	img: string;
	validite: string;
	id_user: string;
	id_type: string;


	created_at: Date;
	updated_at: Date;
	deleted_at: Date;
	enseignes: Enseigne[];
	magasins: Magasin[];
	themes: Theme[];
	doc_type: NewsType;


	@dateValidite()
	date_validite:Date;

	@categoriesList()
	categoriesList: string;

	@creationDate()
	creation_date:string;

	@categoriesCalc()
	categories:Category[];

}

export function creationDate() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) {
				var local = new Date(this.created_at.date);
				return local.toLocaleDateString('fr-FR');
			},
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}
export function dateValidite() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) {
				let date = new Date(this.validite*1000).toLocaleDateString("fr-FR");
				
				return date;
			},
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}

export function categoriesCalc() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) {
				let categories = [];
				this.themes.map((theme) => theme.categories.map((category) => {
					
					categories.push(category) // rapid fix, need to be optimized
					
				}));
				return categories;
			},
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}
export function categoriesList() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) {
				let categories = {};
				this.themes.map((theme) => theme.categories.map((category) => {
					if (!categories[category.id]) {
						categories[category.id] = category.nom
					};
				}))
				return Object.values(categories).join(' - ');
			},
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}