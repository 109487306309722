import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { ClassroomsQuestion } from '@models/classroom-question.model';
import { Classroom } from '@models/classroom.model';
import { ClassroomsService } from './classrooms.service';

const log = new Logger('ClassroomQuestionService');


@Injectable()
export class ClassroomQuestionService {

	constructor(
		private apiService: ApiService,
		private classroomsService:ClassroomsService
	) { }

	private readonly _questions = new BehaviorSubject<ClassroomsQuestion[]>([]);
	readonly questions$ = this._questions.asObservable();

	private readonly _classroom = new BehaviorSubject<Classroom>({} as Classroom);
	readonly classroom$ = this._classroom.asObservable();


	get questions(): ClassroomsQuestion[] {
		return this._questions.getValue();
	}

	set questions(val: ClassroomsQuestion[]) {
		this._questions.next(val);
	}

	get classroom(): Classroom {
		return this._classroom.getValue();
	}

	set classroom(val: Classroom) {
		this._classroom.next(val);
	}

	public create(data): Observable<ClassroomsQuestion> {
		log.debug('create', data);

		return this.apiService.post("/classroomsquestions/", data).pipe(map((data) => {
			let question = new ClassroomsQuestion();
			Object.assign(question, data.data);
			this.questions = [...this.questions, question];
			return question;
		}));
	}


	public update(data): Observable<ClassroomsQuestion> {
		log.debug('update', data);

		return this.apiService.put(`/classroomsquestions/${data.id}`, data).pipe(map((data) => {
			let question = new ClassroomsQuestion();
			Object.assign(question, data.data);

			let questions = this.questions.reduce((ds, d) => {

				if (d.id === question.id) {
					Object.assign(d, question);
				}
				return ds.concat(d);
			}, []);

			this.questions = [...questions];

			return question;
		}));
	}


	public getById(id): Observable<ClassroomsQuestion> {
		log.debug('getById', id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/classroomsquestion/${id}`).pipe(
				map((data) => {
					let question = new ClassroomsQuestion();
					Object.assign(question, data);
					return question;
				})).subscribe((data) => { resolve(data) });
		})
		return (from(promise) as Observable<ClassroomsQuestion>);
	}

	public getAll(id_classroom): Observable<ClassroomsQuestion[]> {
		log.debug('getAll');


		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/classroomsquestions/${id_classroom}`).pipe(
				map((data) => data.questions.map((data) => {
					let question = new ClassroomsQuestion();
					Object.assign(question, data);
					return question;
				}))).subscribe((data) => {
					this.questions = [...data];


					if(this.questions.length == 0 ){
						this.classroomsService.getById(id_classroom).subscribe((data)=>{
							
							this.classroom = data;
						})
					}else{
						this.classroom = this.questions[0]['classroom'];
					}

					

					resolve(data);
				});
		});

		return (from(promise) as Observable<ClassroomsQuestion[]>);



	}


	public delete(id): Observable<boolean> {
		log.debug('delete', id);
		return this.apiService.delete(`/classroomsquestionss/${id}`).pipe(map((data) => {
			this.questions = this.questions.filter(item => item.id != id);
			return true;
		}));
	}
}