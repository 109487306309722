export class Meeting {
	meetingName: string;
	meetingID: string;
	internalMeetingID: string;
	createTime: string;
	createDate: Date;
	voiceBridge: number;
	dialNumber: string;
	attendeePW: string;
	moderatorPW: string;
	running: false;
	duration: number;
	hasUserJoined: boolean;
	recording: boolean;
	hasBeenForciblyEnded: boolean;
	startTime: number;
	endTime: number;
	participantCount: number;
	listenerCount: number;
	voiceParticipantCount: number;
	videoCount: number;
	maxUsers: number;
	moderatorCount: number;
	attendees: any[];
	isBreakout: boolean;
	runningTime: MeetingRunningTime;

	@createDateFormatted()
	createDateFormatted:Date;
}

export class MeetingRunningTime {
	y: number;
	m: number;
	d: number;
	h: number;
	i: number;
	s: number;
	f: number;
	weekday: number;
	weekday_behavior: number;
	first_last_day_of: number;
	invert: number;
	days: number;
	special_type: number;
	special_amount: number;
	have_weekday_relative: number;
	have_special_relative: number;
}



export function createDateFormatted() {
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
			get(this: any) { 
				let tmpDate = this.createDate.date.split('.')[0];
				var arr = tmpDate.split(/[- :]/),
				date = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]).toLocaleString("fr-FR");
				return date;
			},
			set(value: any) { },
			enumerable: true,
			configurable: true,
		};

		Object.defineProperty(target, name, descriptor);
	};
}