import { Salon } from "./salon.model";
import { SalonReponse } from "./salon-reponse.model";

import { formatReponses } from '@helpers/format-reponses';
import { Typesquestion } from "./typesquestion.model";

export class SalonQuestion{
	id:string;
	nom:string;
	id_type:string;
	id_salon:string;
	reponses:SalonReponse[];
	types_question:Typesquestion;
	module:Salon[];

	@idType()
	idType:number;

	@reponsesList()
	reponsesList:string;
}

export function idType(){
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
		  get(this: any) { return parseInt(this.id_type);},
		  set(value: any) { },
		  enumerable: true,
		  configurable: true,
		};
	
		Object.defineProperty(target, name, descriptor);
	  };
}
export function reponsesList(){
	return (target: {} | any, name?: PropertyKey): any => {
		const descriptor = {
		  get(this: any) { return formatReponses(this.reponses);},
		  set(value: any) { },
		  enumerable: true,
		  configurable: true,
		};
	
		Object.defineProperty(target, name, descriptor);
	  };
}