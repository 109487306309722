import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Flashform } from '@models/flashform.model';

let log = new Logger('moduleService');


@Injectable()
export class FlashformsService {

	constructor(
		private apiService: ApiService
	) { }

	private readonly _flashforms = new BehaviorSubject<Flashform[]>([]);
	readonly flashforms$ = this._flashforms.asObservable();

	private readonly _flashforms_user = new BehaviorSubject<Flashform[]>([]);
	readonly flashforms_user$ = this._flashforms_user.asObservable();

	get flashforms(): Flashform[] {
		return this._flashforms.getValue();
	}
	set flashforms(val: Flashform[]) {
		this._flashforms.next(val);
	}

	get flashforms_user(): Flashform[] {
		return this._flashforms_user.getValue();
	}
	set flashforms_user(val: Flashform[]) {
		this._flashforms_user.next(val);
	}

	public getAll(): Observable<Flashform[]> {
		log.debug('getAll');

		if (this.flashforms.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/flashforms').pipe(
					map((data) => data.flashforms.map((data) => {
						let flashform = new Flashform();
						Object.assign(flashform, data);
						return flashform;
					}))).subscribe((data) => {
						this.flashforms = [ ...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<Flashform[]>);

		} else {

			return this.flashforms$;
		}
	}

	public getById(id): Observable<Flashform> {
		log.debug('getById',id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/flashforms/${id}`).pipe(
				map((data) => {
					let flashform = new Flashform();
					Object.assign(flashform, data);
					return flashform;
				})).subscribe((data)=>{resolve(data)});
		})
		return (from(promise) as Observable<Flashform>);
	}

	public getAllByUserId(userId:string){
		log.debug('getAllByUserId');

		if (this.flashforms_user.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get(`/flashforms/user/${userId}`).pipe(
					map((data) => data.flashforms.map((data) => {
						let flashform = new Flashform();
						Object.assign(flashform, data);
						return flashform;
					}))).subscribe((data) => {
						this.flashforms_user = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<Flashform[]>);

		} else {

			return this.flashforms_user$;
		}
	}

	public create(data): Observable<Flashform> {
		log.debug('create', data);

		return this.apiService.post("/flashforms/", data).pipe(map((data) => {
			let flashform = new Flashform();
			Object.assign(flashform, data.data);
			this.flashforms = [...this.flashforms, flashform];
			return flashform;
		}));
	}

	
	public update(data): Observable<Flashform> {
		log.debug('update', data);

		return this.apiService.put(`/flashforms/${data.id}`, data).pipe(map((data) => {
			let flashform = new Flashform();
			Object.assign(flashform, data.data);
			let flashforms = this.flashforms.reduce((ds, d) => {

				if (d.id === flashform.id) {
					Object.assign(d, flashform);
				}
				return ds.concat(d);
			}, []);

			this.flashforms = [...flashforms];

			return flashform;
		}));
	}

	public delete(id):Observable<boolean>
	{
		log.debug('delete', id);
		return this.apiService.delete(`/flashforms/${id}`).pipe(map((data) => {
			this.flashforms = this.flashforms.filter(item => item.id != id);
			return true;
		}));
	}

	public createScore(data):Observable<boolean>
	{
		log.debug('createScore');
		return this.apiService.post(`/flashforms/score`,data).pipe(map((data) => {
			//log.debug(data);
			return true;
		}));
	}
}