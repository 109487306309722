

import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Flashform } from "@models/flashform.model";
import { FlashformQuestion } from "@models/flashform-question.model";
import { Injectable } from '@angular/core';

const log = new Logger('FlashformsQuestionService');



@Injectable()
export class FlashformsQuestionService {

	constructor(
		private apiService: ApiService
	) { }

	private readonly _questions = new BehaviorSubject<FlashformQuestion[]>([]);
	readonly questions$ = this._questions.asObservable();

	private readonly _flashform = new BehaviorSubject<Flashform>({} as Flashform);
	readonly flashform$ = this._flashform.asObservable();


	get questions(): FlashformQuestion[] {
		return this._questions.getValue();
	}

	set questions(val: FlashformQuestion[]) {
		this._questions.next(val);
	}

	get flashform(): Flashform {
		return this._flashform.getValue();
	}

	set flashform(val: Flashform) {
		this._flashform.next(val);
	}

	public create(data): Observable<FlashformQuestion> {
		log.debug('create', data);

		return this.apiService.post("/flashformsquestions/", data).pipe(map((data) => {
			let question = new FlashformQuestion();
			Object.assign(question, data.data);
			this.questions = [...this.questions, question];
			return question;
		}));
	}


	public update(data): Observable<FlashformQuestion> {
		log.debug('update', data);

		return this.apiService.put(`/flashformsquestions/${data.id}`, data).pipe(map((data) => {
			let question = new FlashformQuestion();
			Object.assign(question, data.data);

			let questions = this.questions.reduce((ds, d) => {

				if (d.id === question.id) {
					Object.assign(d, question);
				}
				return ds.concat(d);
			}, []);

			this.questions = [...questions];

			return question;
		}));
	}


	public getById(id): Observable<FlashformQuestion> {
		log.debug('getById', id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/flashformsquestion/${id}`).pipe(
				map((data) => {
					let question = new FlashformQuestion();
					Object.assign(question, data);

					return question;
				})).subscribe((data) => { resolve(data) });
		})
		return (from(promise) as Observable<FlashformQuestion>);
	}

	public getAll(id_flashform): Observable<FlashformQuestion[]> {
		log.debug('getAll');


		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/flashformsquestions/${id_flashform}`).pipe(
				map((data) => data.questions.map((data) => {
					let question = new FlashformQuestion();
					Object.assign(question, data);
					return question;
				}))).subscribe((data) => {
					this.questions = [...data];

					if (this.questions.length > 0) {
						this.flashform = this.questions[0]['flashform'];
					}

					resolve(data);
				});
		});

		return (from(promise) as Observable<FlashformQuestion[]>);
	}

	public delete(id): Observable<boolean> {
		log.debug('delete', id);
		return this.apiService.delete(`/flashformsquestions/${id}`).pipe(map((data) => {
			this.questions = this.questions.filter(item => item.id != id);
			return true;
		}));
	}
}