import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '@core/logger.service';
import { Module } from '@models/module.model';

let log = new Logger('moduleService');


@Injectable()
export class ModuleService {




	constructor(
		private apiService: ApiService
	) { }

	private readonly _modules = new BehaviorSubject<Module[]>([]);
	readonly modules$ = this._modules.asObservable();

	private readonly _modules_user = new BehaviorSubject<Module[]>([]);
	readonly modules_user$ = this._modules_user.asObservable();



	get modules(): Module[] {
		return this._modules.getValue();
	}



	set modules(val: Module[]) {
		this._modules.next(val);
	}


	
	get modules_user(): Module[] {
		return this._modules_user.getValue();
	}



	set modules_user(val: Module[]) {
		this._modules_user.next(val);
	}


	public create(data): Observable<Module> {
		log.debug('create', data);

		return this.apiService.post("/modules/", data).pipe(map((data) => {
			let module = new Module();
			Object.assign(module, data.data);
			this.modules = [...this.modules, module];
			return module;
		}));
	}

	
	public update(data): Observable<Module> {
		log.debug('update', data);

		return this.apiService.put(`/modules/${data.id}`, data).pipe(map((data) => {
			let module = new Module();
			Object.assign(module, data.data);
			
			let modules = this.modules.reduce((ds, d) => {

				if (d.id === module.id) {
					Object.assign(d, module);
				}

				return ds.concat(d);
			}, []);

			this.modules = [...modules];

			return module;
		}));
	}

	public order(modules): Observable<Boolean> {
		log.debug('order', modules);

		return this.apiService.post(`/modules/order`, {"modules":modules}).pipe(map((data) => {

			return true;
		}));
	}

	public getById(id): Observable<Module> {
		log.debug('getById',id);
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/modules/${id}`).pipe(
				map((data) => {
					let module = new Module();
					Object.assign(module, data);
					return module;
				})).subscribe((data)=>{resolve(data)});
		})
		return (from(promise) as Observable<Module>);
	}

	public getByIdWithProgress(id_module, id_user): Observable<Module> {
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/modules/${id_module}/${id_user}`).pipe(
				map((data) => {
					let module = new Module();
					Object.assign(module, data);
					return module;
				})).subscribe((data)=>{resolve(data)});
		})
		return (from(promise) as Observable<Module>);
	}

	public getAll(): Observable<Module[]> {
		log.debug('getAll');

		if (this.modules.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get('/modules').pipe(
					map((data) => data.modules.map((data) => {
						let module = new Module();
						Object.assign(module, data);
						return module;
					}))).subscribe((data) => {
						this.modules = [ ...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<Module[]>);

		} else {

			return this.modules$;
		}

	}

	public getAllByUserId(userId:string){
		log.debug('getAllByUserId');

		if (this.modules_user.length == 0) {
			let promise = new Promise((resolve, reject) => {
				this.apiService.get(`/modules/user/${userId}`).pipe(
					map((data) => data.modules.map((data) => {
						let module = new Module();
						Object.assign(module, data);
						return module;
					}))).subscribe((data) => {
						this.modules_user = [...data];
						resolve(data);
					});
			});
			return (from(promise) as Observable<Module[]>);

		} else {

			return this.modules_user$;
		}
	}

	public logOnDownload(id_user, id_module): Observable<any> {
		log.debug('logOnDownload');

		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/modules/log/${id_user}/${id_module}`).subscribe((data) => {
					resolve(data);
				});
		});
		return (from(promise) as Observable<any>);
	}


	public delete(id):Observable<boolean>
	{
		log.debug('delete', id);
		return this.apiService.delete(`/modules/${id}`).pipe(map((data) => {
			this.modules = this.modules.filter(item => item.id != id);
			return true;
		}));
	}

	public createScore(data):Observable<boolean>
	{
		log.debug('createScore');
		return this.apiService.post(`/modules/score`,data).pipe(map((data) => {
			//log.debug(data);
			return true;
		}));
	}

	public userCertifications(id_module:string){
		log.debug('userCertifications');
		return this.apiService.get(`/modules/user_certifications/${id_module}`).pipe(map((data) => {
			//log.debug(data.data);
			return data.data;
		}));
	}

	public notify(id):Observable<boolean>{
		log.debug('notify', id);
		return this.apiService.get(`/modules/notify/${id}`).pipe(map((data) => {
			return true;
		}));
	}


	public getModulesByUers(id_user_1,id_user_2){
		log.debug('getModulesByUers');
		let promise = new Promise((resolve, reject) => {
			this.apiService.get(`/modules/users/${id_user_1}/${id_user_2}`).pipe(
				map((data) => data.map((data) => {
					let module = new Module();
					Object.assign(module, data);
					return module;
				}))).subscribe((data) => {
					resolve(data);
				});
		});
		return (from(promise) as Observable<Module[]>);
	}
}