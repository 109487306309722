import { Enseigne } from "./enseigne.model";

export class Magasin {

	id:string;
	nom:string;
	adresse?:string;
	ville?:string;
	cp?:string;
	id_enseigne:string;
	deleted_at?:Date;
	enseigne:Enseigne[];



}